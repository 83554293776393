export function lineaPedidoCancelarModal(entity, formValues) {
  return {
    id: formValues.id,
    title:
      "Por favor, confirme que desea cancelar esta nueva línea del pedido",
    subtitle:
      "¡CUIDADO! Se perderán todos los datos introducidos para esta nueva línea del pedido. Esta acción no se puede deshacer.",
    buttons: [
      {
        text: "Confirmar",
        action: "link",
        classes: "unsafe",
        link: `/ver-pedido`,
        entity: entity,
      },
      {
        text: "Cancelar",
        action: "closeModal",
        classes: "safe",
        entity: entity,
      },
    ],
  };
}
