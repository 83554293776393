import React, { useState, useEffect } from "react";
//Styles
import "../../../assets/css/buscar-prenda.css";
//Components
import Table from "../../tableSections/Table";
import Loading from "../../tableSections/Loading";
import ModalGetPrendaTypes from "../../modal/ModalGetPrendaTypes";
//Cookie token
import cookie from "react-cookies";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Notifications
import { toast } from "react-toastify";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadOriginalResults,
  loadWorkResults,
  loadSettings,
  loadPaginatorItems,
  reloadResults,
} from "../../../actions/tableDataActions";
//External Function
import { getNewResults } from "../../../utilities/prendaTypes";
//Utilities
import { paginator, getPaginatorNavItems } from "../../../utilities/paginator";

const PrendaTypes = () => {
  const token = cookie.load("usertk"); // Used to redirect not logged in access attempts.
  //Store data
  const { reload, search, filters, originalData, settings, orderBy } =
    useSelector((state) => state.dataTable);

  //Section Settings
  const defaultSettings = {
    entity: "prendaType",
    queryName: "getPrendaTypes",
    title: "Buscar tipos de prendas",
    text: "Puede utilizar el buscador para encontrar un tipo de prenda por su nombre.",
    createButtonText: "Crear Nuevo tipo de prenda",
    createButtonLink: "/crear-tipo-de-prenda",
    tableHeaders: [
      { text: "Nombre", size: 75, orderBy: true, field: "name" },

      {
        text: "Opciones disponibles",
        size: 25,
        orderBy: false,
        field: "options",
      },
    ],
    searchLabel: "Buscar tipo de prenda",
    paginationSettings: { page: 1, perPage: 6 },
    rowContent: [
      { size: 75, content: "name", align: "left" },
      {
        size: 25,
        buttons: [
          { text: "Ver", action: "modal", classes: "safe" },
          {
            text: "Editar",
            action: "link",
            link: "/editar-tipo-de-prenda",
            classes: "neutral",
          },
          { text: "Eliminar", action: "modal", classes: "unsafe" },
        ],
      },
    ],
  };

  //States
  const [displayTable, setDisplayTable] = useState(false);

  //API
  const GET_PRENDAS = gql`
    query getPrendaTypes {
        getPrendaTypes {
        id
        name
      }
    }
  `;

  const [getPrendaTypes, { error, data }] = useLazyQuery(GET_PRENDAS);

  //Functions
  const goHome = () => {
    //Redirects if not logged in
    window.location.href = "/";
  };

  //Redux actions
  const dispatch = useDispatch();
  const storeOriginalLoad = (processedData) =>
    dispatch(loadOriginalResults(processedData));
  const storeWorkLoad = (paginatedResults) =>
    dispatch(loadWorkResults(paginatedResults));
  const storeSettings = (defaultSettings) =>
    dispatch(loadSettings(defaultSettings));
  const loadNewPaginatorItems = (paginatorItems) =>
    dispatch(loadPaginatorItems(paginatorItems));
  const storeReloadResults = (config) => dispatch(reloadResults(config));
  const loadNewResults = (newResults) => dispatch(loadWorkResults(newResults));

  //useEffect
  useEffect(() => {
    // Results without error
    if (data && !error) {
      // Gets paginator items
      const paginatorItems = getPaginatorNavItems(
        data.getPrendaTypes,
        settings.paginationSettings.perPage,
        settings.paginationSettings.page,
      );

      //Load work copy paginated
      let paginatedResults = {
        getPrendaTypes: paginator(
          data.getPrendaTypes,
          settings.paginationSettings.page,
          settings.paginationSettings.perPage
        ),
      };

      storeWorkLoad(paginatedResults); // Loads work copy in store
      //Load pagination items
      loadNewPaginatorItems(paginatorItems);

      // Loads original copy in store adding dynamic select options
      let processedData = {
        getPrendaTypes: data.getPrendaTypes,
      };
      storeOriginalLoad(processedData);

      // Displays table
      setDisplayTable(true);
    } else if (data && data.getPrendas.length === 0) {
      // No results message display
      const Msg = ({ closeToast, toastProps }) => (
        <div>
          No existe ningún resultado.
          <br />
          <br />
          Por favor, modifique la búsqueda o los filtros aplicados.
        </div>
      );
      toast.error(<Msg />);
    } else if (error) {
      // Technical error display
      const Msg = ({ closeToast, toastProps }) => (
        <div>
          Se ha producido un error técnico
          <br />
          <br />
          Por favor, contacte con nosotros en el email que encontrará al pie de
          esta página.
        </div>
      );
      console.log(error);
      toast.error(<Msg />);
    }
    // eslint-disable-next-line
  }, [data, error]);

  useEffect(() => {
    //Generates new content and pagination
    if (reload) {
      //reset load flag to false to avoid loop
      storeReloadResults(false);

      // Gets new results
      const newResults = getNewResults(originalData, search, filters, orderBy);

      // Gets paginator items
      const paginatorItems = getPaginatorNavItems(
        newResults.getPrendaTypes,
        settings.paginationSettings.perPage,
        settings.paginationSettings.page,
      );

      // Gets paginated results
      let paginatedResults = {
        getPrendaTypes: paginator(
          newResults.getPrendaTypes,
          settings.paginationSettings.page,
          settings.paginationSettings.perPage
        ),
      };
      //Load paginated results
      loadNewResults(paginatedResults);
      //Load pagination items
      loadNewPaginatorItems(paginatorItems);
    }
    // eslint-disable-next-line
  }, [reload]);

  useEffect(() => {
    //Runs query at first load
    getPrendaTypes();
    storeSettings(defaultSettings);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!token ? (
        goHome()
      ) : (
        <>
          <div className="table-container">
            <Loading />
            {displayTable && <Table />}
          </div>
          <ModalGetPrendaTypes />
        </>
      )}
    </>
  );
};

export default PrendaTypes;
