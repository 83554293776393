export function getFormQueryParameters(formValues, settings) {
  let res = JSON.stringify(formValues);

  //First unnecessary brackets
  res = res.replace("{", "(");

  //last unnecessary brackets
  var posLastBracket = res.lastIndexOf("}");
  res = res.substring(0, posLastBracket) + ")";

  //First keys unnecessary quotation marks
  res = res.replaceAll('("', "(").replaceAll(',"', ",").replaceAll('{"', "{");

  //Last keys unnecessary quotation marks
  res = res.replaceAll('":', ":");

  //Check if form has variableRows
  if (settings.variableRows) {
    res = [res.substr(0, 1), "input: { ", res.substr(1, res.length - 1)].join(
      ""
    );
    res = [
      res.substr(0, res.length - 1),
      " }",
      res.substr(res.length - 1, 1),
    ].join("");
  }

  if (!formValues || Object.size(formValues) === 0) {
    return "";
  } else {
    return res;
  }
}

export function getMutationData(entity, id, textAction, formValues, settings) {
  let res = {
    queryFields: ["id"],
  };
  let formQueryParameters = getFormQueryParameters(formValues, settings);

  //Form mutation parameters
  switch (textAction) {
    case "Confirmar":
      res.queryParameters = `(id: ${id})`;
      res.queryName = "delete";
      break;
    case "Crear":
      res.queryParameters = formQueryParameters;
      res.queryName = "create";
      break;
    case "Actualizar":
      res.queryParameters = formQueryParameters;
      res.queryName = "update";
      break;
    default:
      break;
  }

  //Form mutation name
  res.queryName = res.queryName +=
    entity.charAt(0).toUpperCase() + entity.slice(1);

  return res;
}

Object.size = function (obj) {
  var size = 0,
    key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) size++;
  }
  return size;
};

export function getActionString(action) {
  //Returns action name for result toast notification
  let res = "";
  switch (action) {
    case "save":
      res = "cread";
      break;
    case "update":
      res = "editad";
      break;
    case "delete":
      res = "eliminad";
      break;
    default:
      break;
  }
  return res;
}

export function getMessageOk(entity, action, id) {
  //Returns full mutation result ok message
  let res = [];
  const actionString = getActionString(action);
  switch (entity) {
    case "prenda":
      res[0] = "Prenda " + actionString + "a con éxito";
      res[1] = "/prendas";
      break;
    case "prendaType":
      res[0] = "Tipo de prenda " + actionString + "a con éxito";
      res[1] = "/buscar-tipo-de-prenda";
      break;
    case "cliente":
      res[0] = "Cliente " + actionString + "o con éxito";
      res[1] = "/clientes";
      break;
    case "pedido":
      res[0] = "Pedido " + actionString + "o con éxito";
      res[1] = "/buscar-pedido";
      break;
    case "pedidoRow":
      res[0] = "Línea del pedido " + actionString + "a con éxito";
      res[1] = "/ver-pedido/" + id;
      break;
    case "country":
      res[0] = "País " + actionString + "o con éxito";
      res[1] = "/paises";
      break;
    case "province":
      res[0] = "Provincia " + actionString + "a con éxito";
      res[1] = "/provincias/ver/" + id;
      break;
    case "location":
      res[0] = "Población " + actionString + "a con éxito";
      res[1] = "/poblaciones/ver/" + id;
      break;
    case "workplace":
      res[0] = "Centro de trabajo " + actionString + "o con éxito";
      res[1] = "/centros-de-trabajo";
      break;
    case "businessGroup":
      res[0] = "Grupo de Empresas " + actionString + "o con éxito";
      res[1] = "/buscar-grupo-de-empresas";
      break;
    default:
      break;
  }
  return res;
}
