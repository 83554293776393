import React, { useEffect, useState } from "react";
//Redux
import { useSelector, useDispatch } from "react-redux";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Redux actions
import {
  loadFormValue,
  loadGarmentRowDefault,
} from "../../../../../actions/formDataActions";

const CreateOrderButtonTemplate = ({ setShowPreview }) => {
  //Redux Data
  const { formValues } = useSelector((state) => state.dataForm);

  //States
  const [showButton, setShowButton] = useState(true);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));
  const storeLoadGarmentRowDefault = (rowDefault) =>
    dispatch(loadGarmentRowDefault(rowDefault));

  //API
  const GET_ORDER_TEMPLATE = gql`
    query getOrderTemplate {
      getOrderTemplate(id: ${formValues.template_id}) {
        config_data
      }
    }
  `;

  const [getOrderTemplate, { error, data }] = useLazyQuery(GET_ORDER_TEMPLATE);

  const retriveAction = () => {
    setShowPreview(true);
    setShowButton(false);
  };

  //useEffect
  useEffect(() => {
    getOrderTemplate();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && !error) {
      const dataToAssign = JSON.parse(data.getOrderTemplate.config_data)[0];

      storeLoadFormValue(["job", dataToAssign.job]);
      storeLoadFormValue(["workplace", dataToAssign.workplace]);
      storeLoadFormValue(["name", dataToAssign.name]);
      storeLoadFormValue(["sex", dataToAssign.sex]);
      storeLoadFormValue(["surname1", dataToAssign.surname1]);
      storeLoadFormValue(["surname2", dataToAssign.surname2]);
      storeLoadFormValue(["worker_code", dataToAssign.worker_code]);

      //Get garmentRows
      // eslint-disable-next-line
      dataToAssign.garments.map((garment) => {
        storeLoadGarmentRowDefault({
          prenda_id: garment.prenda_id,
          match: garment.name,
          sizeValue: garment.sizeValue,
          quantity: garment.quantity,
        });
      });
    }

    // eslint-disable-next-line
  }, [data, error]);
  return (
    <>
      {showButton && (
        <button className="button safe mt-24" onClick={retriveAction}>
          Previsualizar resultado
        </button>
      )}
    </>
  );
};

export default CreateOrderButtonTemplate;
