import React from "react";
import "./App.css";
//Cookie token
import cookie from "react-cookies";
//Router
import Routes from "./routes/Routes";
import { BrowserRouter as Router } from "react-router-dom";
//CSS
import "./assets/css/general.css";
//Components
import Header from "./components/header/index.js";
import Footer from "./components/footer";
//Notifications
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//GraphQL
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

function App() {
  const token = cookie.load("usertk");
  const client = new ApolloClient({
    uri: "https://oroelone.desarrollobirdcom.es/graphql",
    cache: new InMemoryCache(),
    headers: {
      Authorization: token ? token.replace("%20", " ") : ``,
    },
  });

  return (
    <Router>
      <ApolloProvider client={client}>
        <div className="App">
          <Header />
          <ToastContainer
            position="top-right"
            autoClose={6000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Routes />
          <Footer />
        </div>
      </ApolloProvider>
    </Router>
  );
}

export default App;
