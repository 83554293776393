export function paginator(res, page, perpage) {

  const pageAmount = getPageAmount(res, perpage);

  let pages = [];
  let resElements = 0;
  //Loop pages
  if (res.length > 0) {
    //If there are results
    for (var i = 1; i <= pageAmount; i++) {
      let thisPage = [];

      //Loop a page
      for (var j = 0; j < perpage; j++) {
        if (res[j * i] && resElements < res.length) {
          thisPage[j] = res[resElements];
          resElements++;
        }
      }
      pages.push(thisPage);
    }
  } else {
    //If NO results
    return pages;
  }
  return pages[page - 1];
}

export function getPaginatorNavItems(results, perpage, page) {
  let res = [];
  let pageAmount;
  if (results.length > 0) {
    pageAmount = getPageAmount(results, perpage);
  } else {
    return false;
  }

  //Show in groups of 5 elements. Current if possible in the central element
  //If pageAmount is < 6
  if (pageAmount < 6) {
    for (let k = 1; k <= pageAmount; k++) {
      res.push(k);
    }
  } else {
    //pageAmount >= 6
    if (page <= 3) {
      //If currentpage is countained on first 5 elements
      res = [1, 2, 3, 4, 5, "..."];
    } else {
      // If currentPage is on the last three elements
      if (page >= pageAmount - 2) {
        res = [
          "...",
          pageAmount - 4,
          pageAmount - 3,
          pageAmount - 2,
          pageAmount - 1,
          pageAmount,
        ];
      } else {
        //CurrentPage is in the middle of other pages
        res = ["...", page - 2, page - 1, page, page + 1, page + 2, "..."];
      }
    }
  }

  return res;
}

function getPageAmount(results, perpage) {
  return Math.ceil(results.length / perpage);
}
