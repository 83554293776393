export function prendaEliminarModal(item, entity) {
  return {
    id: item.id,
    title: "Por favor, confirme que desea eliminar esta prenda",
    subtitle: "¡CUIDADO! Esta acción no se puede deshacer",
    image: item.image,
    texts: [
      {
        text: "Nombre",
        value: item.name,
      },
      {
        text: "Referencia",
        value: item.reference,
      },
      {
        text: "Tipo de prenda",
        value: item.type.name,
      },
      {
        text: "Descripción",
        value: item.description,
      },
    ],
    buttons: [
      {
        text: "Confirmar",
        action: "delete",
        classes: "unsafe",
        entity: entity,
        actionType: "mutation",
      },
      {
        text: "Cancelar",
        action: "closeModal",
        classes: "safe",
        entity: entity,
      },
    ],
  };
}
