import React, { useState, useEffect } from "react";
//Styles
import "../../../../assets/css/form.css";
//Cookie token
import cookie from "react-cookies";
//Router
import { useHistory, useParams } from "react-router-dom";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadFormSettings,
  loadFormValue,
} from "../../../../actions/formDataActions";
//Components
import Form from "../../../formSections/Form";

const LocationsUpdate = () => {
  //Redirect if not logged in
  let history = useHistory();
  const token = cookie.load("usertk");
  let { id } = useParams();

  //Store data
  const { settings } = useSelector((state) => state.dataForm);

  //States
  const [displayForm, setDisplayForm] = useState(false);

  //API
  const GET_LOCATION_2UPDATE = gql`
    query getLocation {
      getLocation(id: ${id}) {
        id
        name
        province{
          id
          name
          country {
            name
            id
          }
        }
      }
    }
  `;

  const [getLocation, { error, data }] = useLazyQuery(GET_LOCATION_2UPDATE);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));
  const storeLoadSettings = (defaultSettings) =>
    dispatch(loadFormSettings(defaultSettings));

  //useEffect
  useEffect(() => {
    if (settings && settings !== {}) {
      setDisplayForm(true);
    } else {
      setDisplayForm(false);
    }
    // eslint-disable-next-line
  }, [settings]);

  useEffect(() => {
    if (data && !error) {
      //Section Settings
      const defaultSettings = {
        id: id,
        mutationBackID: data.getLocation.province.id,
        entity: "location",
        queryName: "updateLocation",
        title: `Editar ${data.getLocation.name}, población de ${data.getLocation.province.name} (${data.getLocation.province.country.name})`,
        text: "Por favor, modifique el nombre de la población y después pulse en aceptar para que los cambios se guarden.",
        formContent: [
          {
            type: "fieldText",
            name: "name",
            size: 75,
            label: "Nombre de la población",
            placeholder: "Nombre de la población",
            required: true,
            validated: true,
            validation: "notEmpty",
            defaultValue: data.getLocation.name,
          },
          {
            type: "buttons",
            buttons: [
              {
                text: "Actualizar",
                action: "update",
                classes: "safe",
                actionType: "update",
                entity: "location",
                id: id,
              },
              {
                text: "Cancelar",
                action: "link",
                link: "/poblaciones/ver/",
                classes: "unsafe",
                entity: "location",
              },
            ],
          },
        ],
      };
      storeLoadSettings(defaultSettings);
      //Load ids values to store
      storeLoadFormValue(["id", parseInt(id)]);
      storeLoadFormValue(["province_id", data.getLocation.province.id]);
    }

    // eslint-disable-next-line
  }, [data, error]);

  useEffect(() => {
    getLocation();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!token ? (
        history.push("/")
      ) : (
        <>
          <div className="table-container">{displayForm && <Form />}</div>
        </>
      )}
    </>
  );
};

export default LocationsUpdate;
