import React from "react";

const CreateButton = ({ text, link }) => {

  //Function
  const redirect = () => {
    window.location.href = link;
  };

  return (
    <div className="create">
      <button onClick={redirect}>
        {text}
      </button>
    </div>
  );
};

export default CreateButton;
