import React, { useState, useEffect } from "react";
//Styles
import "../../../assets/css/buscar-prenda.css";
//Components
import Table from "../../tableSections/Table";
import Loading from "../../tableSections/Loading";
import ModalGetPrenda from "../../modal/ModalGetPrenda";
//Cookie token
import cookie from "react-cookies";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Notifications
import { toast } from "react-toastify";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadOriginalResults,
  loadWorkResults,
  loadSettings,
  initFilters,
  loadPaginatorItems,
  reloadResults,
} from "../../../actions/tableDataActions";
//External Function
import { getNewResults } from "../../../utilities/prendas";
//Utilities
import { paginator, getPaginatorNavItems } from "../../../utilities/paginator";

const Prendas = () => {
  const token = cookie.load("usertk"); // Used to redirect not logged in access attempts.
  //Store data
  const { reload, search, filters, originalData, settings, orderBy } =
    useSelector((state) => state.dataTable);

  //Section Settings
  const defaultSettings = {
    entity: "prenda",
    queryName: "getPrendas",
    title: "Buscar Prendas",
    text: "Puede utilizar el buscador para encontrar una prenda por su nombre, referencia o tipo.",
    createButtonText: "Crear Nueva Prenda",
    createButtonLink: "/crear-prenda",
    tableHeaders: [
      { text: "Referencia", size: 20, orderBy: false, field: "reference" },
      { text: "Nombre", size: 35, orderBy: true, field: "name" },
      { text: "Tipo", size: 20, field: "type" },
      {
        text: "Opciones disponibles",
        size: 25,
        orderBy: false,
        field: "options",
      },
    ],
    searchLabel: "Buscar Prenda",
    filterSettings: [
      {
        name: "prenda-type",
        placeholder: "Tipo de prenda",
        queryname: "getPrendasType",
      },
    ],
    paginationSettings: { page: 1, perPage: 6 },
    rowContent: [
      { size: 20, content: "reference", align: "left" },
      { size: 35, content: "name", align: "left" },
      { size: 20, content: "type", align: "left" },
      {
        size: 25,
        buttons: [
          { text: "Ver", action: "modal", classes: "safe" },
          {
            text: "Editar",
            action: "link",
            link: "/editar-prenda",
            classes: "neutral",
          },
          { text: "Eliminar", action: "modal", classes: "unsafe" },
        ],
      },
    ],
  };
  const initalFilters = [
    {
      name: "prenda-type",
      value: "",
      index: 0,
    },
  ];

  //States
  const [displayTable, setDisplayTable] = useState(false);

  //API
  const GET_PRENDAS = gql`
    query getPrendas {
      getPrendas {
        id
        name
        reference
        type {
          id
          name
        }
      }
    }
  `;

  const [getPrendas, { error, data }] = useLazyQuery(GET_PRENDAS);

  //Functions
  const goHome = () => {
    //Redirects if not logged in
    window.location.href = "/";
  };

  //Redux actions
  const dispatch = useDispatch();
  const storeOriginalLoad = (processedData) =>
    dispatch(loadOriginalResults(processedData));
  const storeWorkLoad = (paginatedResults) =>
    dispatch(loadWorkResults(paginatedResults));
  const storeSettings = (defaultSettings) =>
    dispatch(loadSettings(defaultSettings));
  const storeInitialFilters = (initalFilters) =>
    dispatch(initFilters(initalFilters));
  const loadNewPaginatorItems = (paginatorItems) =>
    dispatch(loadPaginatorItems(paginatorItems));
  const storeReloadResults = (config) => dispatch(reloadResults(config));
  const loadNewResults = (newResults) => dispatch(loadWorkResults(newResults));

  //useEffect
  useEffect(() => {
    // Results without error
    if (data && !error) {
      //Gets filter options
      let plainData = [];

      // eslint-disable-next-line
      data.getPrendas.map((prenda) => {
        let processedPrenda = {
          id: prenda.id,
          name: prenda.name,
          reference: prenda.reference,
          type: prenda.type.name,
          typeID: prenda.type.id,

        }
        plainData.push(processedPrenda);
      })
      let getPrendasType = getFilterOptions(plainData, ["type"]);
      // Gets paginator items
      const paginatorItems = getPaginatorNavItems(
        plainData,
        settings.paginationSettings.perPage,
        settings.paginationSettings.page,
      );

      //Load work copy paginated
      let paginatedResults = {
        getPrendasType: getPrendasType,
        getPrendas: paginator(
          plainData,
          settings.paginationSettings.page,
          settings.paginationSettings.perPage
        ),
      };

      storeWorkLoad(paginatedResults); // Loads work copy in store
      //Load pagination items
      loadNewPaginatorItems(paginatorItems);

      // Loads original copy in store adding dynamic select options
      let processedData = {
        getPrendas: plainData,
        getPrendasType: getPrendasType,
      };
      storeOriginalLoad(processedData);

      // Displays table
      setDisplayTable(true);
    } else if (data && data.getPrendas.length === 0) {
      // No results message display
      const Msg = ({ closeToast, toastProps }) => (
        <div>
          No existe ningún resultado.
          <br />
          <br />
          Por favor, modifique la búsqueda o los filtros aplicados.
        </div>
      );
      toast.error(<Msg />);
    } else if (error) {
      // Technical error display
      const Msg = ({ closeToast, toastProps }) => (
        <div>
          Se ha producido un error técnico
          <br />
          <br />
          Por favor, contacte con nosotros en el email que encontrará al pie de
          esta página.
        </div>
      );
      console.log(error);
      toast.error(<Msg />);
    }
    // eslint-disable-next-line
  }, [data, error]);

  useEffect(() => {
    //Generates new content and pagination
    if (reload) {
      //reset load flag to false to avoid loop
      storeReloadResults(false);

      // Gets new results
      const newResults = getNewResults(originalData, search, filters, orderBy);

      // Gets paginator items
      const paginatorItems = getPaginatorNavItems(
        newResults.getPrendas,
        settings.paginationSettings.perPage,
        settings.paginationSettings.page,
      );

      // Gets paginated results
      let paginatedResults = {
        getPrendasType: newResults.getPrendasType,
        getPrendas: paginator(
          newResults.getPrendas,
          settings.paginationSettings.page,
          settings.paginationSettings.perPage
        ),
      };
      //Load paginated results
      loadNewResults(paginatedResults);
      //Load pagination items
      loadNewPaginatorItems(paginatorItems);
    }
    // eslint-disable-next-line
  }, [reload]);

  useEffect(() => {
    //Runs query at first load
    getPrendas();
    storeSettings(defaultSettings);
    storeInitialFilters(initalFilters);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!token ? (
        goHome()
      ) : (
        <>
          <div className="table-container">
            <Loading />
            {displayTable && <Table />}
          </div>
          <ModalGetPrenda />
        </>
      )}
    </>
  );
};

export default Prendas;

function getFilterOptions(results, keys) {
  //location, name
  let res = [];
  // eslint-disable-next-line
  results.map((result) => {
    if (res.length === 0 || !res.some((e) => e.name === result[keys[0]])) {
      res.push({ name: result[keys[0]] });
    }
  });
  return res;
}
