import React, { useState, useEffect } from "react";
//Redux
import { useSelector } from "react-redux";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Components
import OrderGarmentRows from "../createOrderInputs/OrderGarmentRows";
import CreateOrderButtonCreate from "../createOrderButtons/CreateOrderButtonCreate";
import CreateOrderButtonCancel from "../createOrderButtons/CreateOrderButtonCancel";

const OrderAssignPreview = ({ exampleOrderRow }) => {
  
  //Redux Data
  const { formValues } = useSelector((state) => state.dataForm);

  //States
  const [garments, setGarments] = useState([]);

  //API
  const GET_PRENDAS_NAMES = gql`
    query getPrendas {
      getPrendas {
        id
        name
      }
    }
  `;

  const [getPrendas, { error, data }] = useLazyQuery(GET_PRENDAS_NAMES);

  //Functions
  const capitalize = (key) => {
    const lowcaseName =
      formValues.order[exampleOrderRow][formValues[key]].toLowerCase();
    const splitCompoundName = lowcaseName.split(" ");

    for (var i = 0; i < splitCompoundName.length; i++) {
      splitCompoundName[i] =
        splitCompoundName[i].charAt(0).toUpperCase() +
        splitCompoundName[i].substring(1);
    }
    return splitCompoundName.join(" ");
  };

  const getGarmentQuantity = () => {
    
    let res = 0;
    // eslint-disable-next-line
    formValues.garments.map((garment) => {      
      if (
        formValues.order[0][garment.quantity] &&
        formValues.order[0][garment.quantity] !== ""
      ) {
        res = res + formValues.order[0][garment.quantity];
      }
    });

    return res;
  };

  const getGarments = () => {
    let res = [];
    if (data && data.getPrendas && !error) {
      // eslint-disable-next-line
      formValues.garments.map((garment) => {
        //Get prenda name by id
        let prendaName = "";
        // eslint-disable-next-line
        data.getPrendas.map((prenda) => {
          if (prenda.id === garment.prenda_id) {
            prendaName = prenda.name;
          }
        });
        res.push({
          name: prendaName !== "" ? prendaName : "- -",
          size:
            garment.sizeValue !== ""
              ? formValues.order[exampleOrderRow][garment.sizeValue]
              : "- -",
          quantity:
            garment.quantity !== 0
              ? formValues.order[exampleOrderRow][garment.quantity]
              : "- -",
        });
      });
    }
    return res;
  };

  //Data to Show
  const name = capitalize("name");
  const surname1 = formValues.order[exampleOrderRow][formValues.surname1]
    ? capitalize("surname1")
    : "";
  const surname2 = formValues.order[exampleOrderRow][formValues.surname2]
    ? capitalize("surname2")
    : "";

  //Join full worker name
  const fullName = `${name} ${surname1} ${surname2}`;

  const job = capitalize("job");
  const workplace = capitalize("workplace");
  const quantity = getGarmentQuantity();
  const workerCode = formValues.order[exampleOrderRow][
    formValues["worker_code"]
  ]
    ? formValues.order[exampleOrderRow][formValues["worker_code"]]
    : "- -";
  const sex = formValues.order[exampleOrderRow][formValues["sex"]]
    ? formValues.order[exampleOrderRow][formValues["sex"]]
    : "- -";

  //useEffect
  useEffect(() => {
    getPrendas();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && data.getPrendas && !error) {
      setGarments(getGarments());
    }
    // eslint-disable-next-line
  }, [data, error]);

  return (
    <>
      <div className="order-preview__title">
        <h1>Previsualización del resultado</h1>
      </div>
      <div className="order-preview__headers">
        <p className="col-30">Nombre</p>
        <p className="col-20">Puesto</p>
        <p className="col-20">C. de trabajo</p>
        <p className="col-10">Cantidad</p>
        <p className="col-10">Nº Interno</p>
        <p className="col-10">Sexo</p>
      </div>
      <div className="order-preview__container">
        <div className="order-preview__container-row">
          <div className="order-preview__container-cell col-30">{fullName}</div>
          <div className="order-preview__container-cell col-20">{job}</div>
          <div className="order-preview__container-cell col-20">
            {workplace}
          </div>
          <div className="order-preview__container-cell col-10">{quantity}</div>
          <div className="order-preview__container-cell col-10">
            {workerCode}
          </div>
          <div className="order-preview__container-cell col-10">{sex}</div>
        </div>
      </div>
      <div className="order-preview__headers-garments">
        <p className="col-60">Nombre de la prenda</p>
        <p className="col-10">Talla</p>
        <p className="col-30">Cantidad</p>
      </div>
      {garments && (
        <>
          <OrderGarmentRows
            garments={garments}
            setGarments={setGarments}
            getGarments={getGarments}
            exampleOrderRow={exampleOrderRow}
          />
          <CreateOrderButtonCreate />
          <CreateOrderButtonCancel />
        </>
      )}
    </>
  );
};

export default OrderAssignPreview;
