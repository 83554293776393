export function validateForm(formFields, formValues, variableRows) {
  //returns array of validation value pairs
  let res = {
    result: true,
    validationResult: [],
    variableValidationResult: [],
  };

  // eslint-disable-next-line
  formFields.map((formField) => {
    //Check if is present
    if (formValues[formField.name]) {
      //Autofilled fields are autovalidated
      if (formField.disabled || formField.name === "spacer") {
        res.validationResult.push([formField.name, true]);
      } else {
        //Switch validation needed
        switch (formField.validation) {
          case "notEmpty":
            //case of inputs with string value
            if (
              (formField.type === "fieldText" ||
                formField.type === "fieldTextArea" ||
                formField.type === "fieldSelect") &&
              formField.required &&
              formValues[formField.name] &&
              formValues[formField.name] !== ""
            ) {
              res.validationResult.push([formField.name, true]);
              //case of file inputs
            } else if (
              formField.type === "fieldUploadFile" &&
              formField.required &&
              formValues[formField.name] &&
              formValues[formField.name].length !== 0
            ) {
              res.validationResult.push([formField.name, true]);
            } else if (
              formField.type === "fieldSelect" &&
              formField.required &&
              formValues[formField.name] &&
              formValues[formField.name] !== ""
            ) {
              res.validationResult.push([formField.name, true]);
            } else {
              res.validationResult.push([formField.name, false]);
            }
            break;
          case "mail":
            //case of email field
            const re =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (
              formField.required &&
              re.test(String(formValues[formField.name]).toLowerCase())
            ) {
              res.validationResult.push([formField.name, true]);
            } else {
              res.validationResult.push([formField.name, false]);
            }
            break;
          default:
            break;
        }
      }
    } else {
      if (
        formField.type !== "buttons" &&
        formField.name !== "spacer" &&
        formField.required
      ) {
        res.validationResult.push([formField.name, false]);
      }
    }
  });

  //In case that is form with variable rows...
  if (variableRows) {

    const variableRowData = formValues[variableRows.entity].create;
    const variableRowFields = variableRows.fieldGroups;

    // eslint-disable-next-line
    variableRowData.map((variableRowD, index) => {
      //Array of new row
      res.variableValidationResult.push([]);
      //Map all fields
      // eslint-disable-next-line
      variableRowFields.map((variableRowField) => {
        if (
          variableRowField.required &&
          variableRowField.type !== "buttons" &&
          variableRowField.type !== "spacer" &&
          (!variableRowD[variableRowField.name] ||
            variableRowD[variableRowField.name] === "" ||
            // eslint-disable-next-line
            variableRowD[variableRowField.name] === NaN)
        ) {
          res.variableValidationResult[index].push([
            variableRowField.name,
            false,
          ]);
        } else if (
          variableRowField.required &&
          variableRowField.type !== "buttons" &&
          variableRowField.type !== "spacer" &&
          variableRowD[variableRowField.name] &&
          variableRowD[variableRowField.name] !== "" &&
          // eslint-disable-next-line
          variableRowD[variableRowField.name] !== NaN
        ) {
          res.variableValidationResult[index].push([
            variableRowField.name,
            true,
          ]);
        }
      });
    });
  }

  // If any false validation not passed
  // eslint-disable-next-line
  res.validationResult.map((vResult) => {
    if (!vResult[1]) {
      res.result = false;
    }
  });

  if (variableRows) {
    if (res.variableValidationResult.length === 0) {
      res.result = false;
    } else {
      // eslint-disable-next-line
      res.variableValidationResult.map((vVRResult) => {
        // eslint-disable-next-line
        vVRResult.map((vVRFieldResult) => {
          if (!vVRFieldResult[1]) {
            res.result = false;
          }
        });
      });
    }
  }

  return res;
}
