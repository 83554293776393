import React, { useState, useEffect } from "react";
//Icons svg
import { ReactComponent as MagnifyingGlass } from "../../assets/images/magnifyingGlass.svg";
import { ReactComponent as Chevron } from "../../assets/images/chevron.svg";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  loadFormVariableRowValue,
  loadNewSelectOptions,
  loadVariableRowValidationState,
} from "../../actions/formDataActions";

const VariableRowFieldSelect = ({ pos, rowNumber }) => {
  //Store data
  const { selectOptions, formValues, settings } = useSelector(
    (state) => state.dataForm
  );

  const { name, label, placeholder, textOne, textTwo, position, defaultValue } =
    useSelector(
      (state) => state.dataForm.settings.variableRows.fieldGroups[pos]
    );

  const { variableRowValidations } = useSelector((state) => state.dataForm);

  const getActualValue = () => {
    let res;

    const initialValue =
      formValues[settings.variableRows.entity].create[rowNumber][name];

      // eslint-disable-next-line
      selectOptions[0].originalOptions.map((selectOption) => {
        if(parseInt(selectOption.id) === initialValue){
          res = selectOption.name;
        }
      })
  
    return res;
  };

  let actualValue = getActualValue();

  //States
  const [valuePlaceholder, setValuePlaceholder] = useState(placeholder);
  const [displayError, setDisplayError] = useState(false);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormVariableRowValue = (valuePair, rowNumber) =>
    dispatch(loadFormVariableRowValue(valuePair, rowNumber));
  const storeLoadNewSelectOptions = (newOptions) =>
    dispatch(loadNewSelectOptions(newOptions, position));
  const storeLoadVariableRowValidationState = (rowNumber, name, validated) =>
    dispatch(loadVariableRowValidationState(rowNumber, name, validated));

  //Functions
  const loadInputValue = (e) => {
    //Loads input value in store
    storeLoadFormVariableRowValue(
      [e.target.dataset.name, parseInt(e.target.dataset.optionvalue)],
      rowNumber
    );

    setValuePlaceholder(e.target.dataset.optionname);

    //upload store validation state
    if (e.target.dataset.optionvalue && e.target.dataset.optionvalue !== "") {
      storeLoadVariableRowValidationState(rowNumber, name, true);
    } else {
      storeLoadVariableRowValidationState(rowNumber, name, false);
    }
  };

  const filterSelectOptions = (e) => {
    //Loads new select options according to search field value
    const searchTerm = e.target.value;
    const newOptions = [];
    if (selectOptions && selectOptions[position]) {
      // eslint-disable-next-line
      selectOptions[position].originalOptions.map((originalOption) => {
        if (
          originalOption.name.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          newOptions.push(originalOption);
        }
      });
    }
    storeLoadNewSelectOptions(newOptions);
  };

  const isCurrent = (option) => {
    //Load style if item is selected
    if (option === formValues[name]) {
      return " current";
    } else {
      return " ";
    }
  };

  //useEffect
  useEffect(() => {
    let resu = false;

    if (variableRowValidations && variableRowValidations[rowNumber]) {
      // eslint-disable-next-line
      variableRowValidations[rowNumber].map((rowValidations) => {
        if (rowValidations[0] === name && !rowValidations[1]) {
          resu = true;
        }
      });
    }

    setDisplayError(resu);
    // eslint-disable-next-line
  }, [variableRowValidations]);

  useEffect(() => {
    //In case of update, load current value into form values

    if (defaultValue) {
      if (
        typeof defaultValue === "object" &&
        !Array.isArray(defaultValue) &&
        defaultValue !== null
      ) {
        storeLoadFormVariableRowValue([name, defaultValue.name], rowNumber);
      } else {
        storeLoadFormVariableRowValue([name, defaultValue], rowNumber);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="input-container select">
      {label && rowNumber === 0 && <div className="input-label">{label}</div>}
      {textOne && <div className="input-text">{textOne}</div>}
      {textTwo && <div className="input-text">{textTwo}</div>}
      <>
        {/* Display collapsed */}
        <div className="input-select-collapsed">
          <input
            type="text"
            disabled
            placeholder={valuePlaceholder}
            className={displayError ? "errorInput" : ""}
            defaultValue={defaultValue ? defaultValue : ""}
            value={actualValue}
          />
          <Chevron />
        </div>
        {/* Display expanded */}
        <div className="input-select-expanded">
          <div className="input-select-expanded__container">
            {/* fixed search */}
            <div className="input-select-expanded__search">
              <input type="text" onChange={filterSelectOptions} />
              <MagnifyingGlass />
            </div>
            <div className="input-select-expanded__option-container">
              {/* Filter - Select - Options - API */}
              {selectOptions[position] &&
                selectOptions[position].workOptions.map((item, index) => (
                  <div
                    className={
                      "input-select-expanded__option " + isCurrent(item.name)
                    }
                    key={index}
                    data-name={name}
                    data-optionname={item.name}
                    data-optionvalue={item.id}
                    onClick={loadInputValue}
                  >
                    {item.name}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default VariableRowFieldSelect;
