import React from "react";
//Icons svg
import { ReactComponent as LogoOroel } from "../../../../assets/images/logo-oroel.svg";

const CountByWorkplaceTemplate = ({ clientName, result, workplace }) => {
  //Date strings
  const getDateString = () => {
    var meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    var diasSemana = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    var f = new Date();
    return (
      diasSemana[f.getDay()] +
      ", " +
      f.getDate() +
      " de " +
      meses[f.getMonth()] +
      " de " +
      f.getFullYear()
    );
  };

  return (
    <>
      {/* heading */}
      <div
        id="count-workplace-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          padding: "2.5% 5%",
          borderBottom: "1px solid black",
        }}
      >
        <div
          id="delivery-note__heading-column"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div>
            <LogoOroel />
          </div>
          <div
            style={{
              fontFamily: "SF Pro Display",
              fontSize: "12px",
              fontWeight: 700,
              margin: "12px 0",
            }}
          >
            Confecciones Oroel S.A.
          </div>
          <div style={{ fontSize: "12px" }}>
            Calle Los Ángeles, 10 - Po. Centrovía 50.198
            <br />
            La Muela (Zaragoza)
            <br />
            Tel. 976-14-92-60 - Fax. 976-14-92-52
            <br />
            E-Mail: comercial@oroel.com
            <br />
            Web: www.grupooroel.com
          </div>
        </div>
        <div
          id="delivery-note__heading-column"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div>
            <h1
              style={{
                marginBottom: 0,
                fontFamily: "SF Pro Display",
                fontSize: "25px",
              }}
            >
              RECUENTO POR
              <br />
              CENTRO DE
              <br />
              TRABAJO
            </h1>
          </div>
          <div
            style={{
              fontFamily: "SF Pro Display",
              fontWeight: 600,
              margin: "12px 0",
              fontSize: "12px",
            }}
          >
            {getDateString()}
          </div>
        </div>
      </div>
      {/* Client data */}
      <div
        id="client-data-container"
        style={{
          display: "flex",
          flexWrap: "wrap",
          padding: "2.5% 5%",
          fontSize: "12px",
          borderBottom: "1px solid black",
          textAlign: "left",
          marginBottom: "2.5%",
        }}
      >
        <div
          style={{
            fontFamily: "SF Pro Display",
            width: "18%",
          }}
        >
          Cliente:
        </div>
        <div
          style={{
            fontFamily: "SF Pro Display",
            fontWeight: 600,
            width: "82%",
          }}
        >
          {clientName}
        </div>
        <div
          style={{
            fontFamily: "SF Pro Display",
            width: "18%",
          }}
        >
          Centro de trabajo:
        </div>
        <div
          style={{
            fontFamily: "SF Pro Display",
            fontWeight: 600,
            width: "82%",
          }}
        >
          {workplace}
        </div>
      </div>
      {result.garments.map((garmentInfo) => (
        <div
          id="garment-container"
          style={{
            paddingLeft: "2.5%",
            paddingRight: "2.5%",
            marginBottom: "2.5%",
          }}
        >
          <div
            id="garment-container__headers-container"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              borderBottom: "1px solid black",
              gap: "5%",
              margin: "0 0 2% 0",
            }}
          >
            <div
              id="garment-container__name-container"
              style={{
                width: "47.5%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <h2
                style={{
                  fontSize: "18px",
                  lineHeight: "18px",
                  marginBottom: "2.5%",
                  fontWeight: 600,
                }}
              >
                {garmentInfo.name}
              </h2>
            </div>
            <div
              id="garment-container__table-headers-container"
              style={{
                width: "47.5%",
                borderTop: "1px solid black",
                display: "flex",
                padding: "2%",
              }}
            >
              <div
                id="garment-container__header-container"
                style={{ fontSize: "12px", fontWeight: 600, width: "33%" }}
              >
                Talla
              </div>
              <div
                id="garment-container__header-container"
                style={{ fontSize: "12px", fontWeight: 600, width: "33%" }}
              >
                Cantidad
              </div>
              <div
                id="garment-container__header-container"
                style={{ fontSize: "12px", fontWeight: 600, width: "33%" }}
              >
                Entregado
              </div>
            </div>
          </div>
          <div
            id="garment-container"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              gap: "5%",
            }}
          >
            <div
              id="garment-totals-container"
              style={{
                width: "47.5%",
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{ width: "100%", paddingLeft: "5%", paddingRight: "5%" }}
              >
                <p style={{ margin: 0 }}>Cantidad Total: {garmentInfo.total}</p>
              </div>
              <div
                style={{ width: "100%", paddingLeft: "5%", paddingRight: "5%" }}
              >
                <p style={{ margin: 0 }}>
                  Total entregado: {garmentInfo.total}
                </p>
              </div>
            </div>
            <div
              id="garment-sizes-container"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "47.5%",
              }}
            >
              {garmentInfo.sizes.map((size, index) => (
                <div
                  id="garment-size-container"
                  style={{
                    width: "100%",
                    display: "flex",
                    padding: "1.2% 5%",
                  }}
                >
                  <div style={{ width: "33%", fontSize: "12px" }}>
                    {size.size ? size.size : "Única"}
                  </div>
                  <div style={{ width: "33%", fontSize: "12px" }}>
                    {size.quantity}
                  </div>
                  <div style={{ width: "33%", fontSize: "12px" }}>
                    {size.quantity}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default CountByWorkplaceTemplate;
