import React from "react";
//Components
import Button from "../tableSections/Button";
import ButtonMutation from "../tableSections/ButtonMutation";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { loadModalContent } from "../../actions/tableDataActions";

const ModalDeleteLineaPedido = () => {
  //Store data
  const { modal, settings } = useSelector((state) => state.dataTable);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadModalContent = (modalContent) =>
    dispatch(loadModalContent(modalContent));

  //Functions
  const closeModal = () => {
    storeLoadModalContent(false);
  };

  return (
    <>
      {modal && (
        <div className="modal__background" onClick={closeModal}>
          <div className="modal__body">
            <div className="modal-body__headers">
              {modal.title && (
                <div className="modal-body-headers__title">{modal.title}</div>
              )}
              {modal.subtitle && (
                <div className="modal-body-headers__subtitle">
                  {modal.subtitle}
                </div>
              )}
            </div>
            <div className="modal-body__info" style={{ width: "100%" }}>
              {modal.image && (
                <div
                  className={
                    "modal-body-info__image " + (!modal.title ? "col-50" : "")
                  }
                >
                  <img src={modal.image} alt={"Imagen de " + settings.entity} />
                </div>
              )}
              <div
                className="modal-body-info__texts "
                style={{ width: "100%" }}
              >
                {modal.infoTitle && (
                  <div className="modal-body-info-texts__title">
                    {modal.infoTitle}
                  </div>
                )}
                {modal.texts && (
                  <>
                    {modal.texts.map((text, index) => (
                      <div key={index}>
                        <div className="modal-body-info-texts__label">
                          {text.text}
                        </div>
                        <div className="modal-body-info-texts__text">
                          {text.value}
                        </div>
                      </div>
                    ))}
                  </>
                )}
                {/* Garment List */}
                <div
                  className="prendas-container"
                  style={{
                    width: "100%",
                    height: "107px",
                    overflow: "hidden",
                    overflowY: "scroll",
                    overflowX: "none",
                  }}
                >
                  {modal.texts[3].html.length > 0 ? (
                    <>
                      <div className="prenda-modal-headers">
                        <div
                          className="prenda-info__label"
                          style={{ width: "60%" }}
                        >
                          Prenda
                        </div>
                        <div
                          className="prenda-info__label"
                          style={{ width: "20%" }}
                        >
                          Talla
                        </div>
                        <div
                          className="prenda-info__label"
                          style={{ width: "20%" }}
                        >
                          Cantidad
                        </div>
                      </div>
                      {modal.texts[3].html.map((garment, index) => (
                        <div
                          className="prenda-container"
                          key={garment.prenda.name + index}
                        >
                          <div className="prenda-info" style={{ width: "60%" }}>
                            <div
                              className="prenda-info__value"
                              style={{ width: "100%" }}
                            >
                              {garment.prenda.name}
                            </div>
                          </div>
                          <div className="prenda-info" style={{ width: "20%" }}>
                            <div className="prenda-info__value">
                              {garment.size}
                            </div>
                          </div>
                          <div className="prenda-info" style={{ width: "20%" }}>
                            <div className="prenda-info__value">
                              {garment.quantity}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="no-results">
                      El pedido no contiene prendas para el trabajador indicado
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-body__buttons">
              {modal.buttons && (
                <>
                  {modal.buttons.map((button) => (
                    <div key={button.text}>
                      {!button.actionType ? (
                        <Button
                          text={button.text}
                          action={button.action}
                          link={button.link}
                          classes={button.classes}
                          id={modal.id}
                          entity={button.entity}
                        />
                      ) : (
                        <ButtonMutation
                          text={button.text}
                          action={button.action}
                          link={button.link}
                          classes={button.classes}
                          id={modal.id}
                          entity={button.entity}
                          key={button.text}
                        />
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalDeleteLineaPedido;
