import React, { useState, useEffect } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { loadFormValue } from "../../../../../actions/formDataActions";

const FieldValueToAssign = ({ name, value }) => {
  //Store data
  const { formValues } = useSelector((state) => state.dataForm);

  //States
  const [displayError, setDisplayError] = useState(true);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));

  //Functions
  const loadInputValue = (e) => {
    storeLoadFormValue([e.target.name, e.target.value]);
  };

  //useEffect
  useEffect(() => {
    //Check if its assigned
    if (Object.values(formValues).indexOf(name) > -1) {
      setDisplayError(false);
    } else {
      setDisplayError(true);
    }
    // eslint-disable-next-line
  }, [
    formValues.name,
    formValues.surname1,
    formValues.surname2,
    formValues.worker_code,
    formValues.dni,
    formValues.sex,
    formValues.workplace,
    formValues.job,
  ]);

  return (
    <div className="input-container col-4">
      <div className="input-label">{name}</div>
      <input
        type="text"
        name={name}
        placeholder="Escriba el nombre del centro de trabajo *"
        className={displayError === true ? " errorInput " : ""}
        onChange={loadInputValue}
        value={value}
        disabled={true}
      />
    </div>
  );
};

export default FieldValueToAssign;
