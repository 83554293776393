import React, { useState, useEffect } from "react";
//Icons svg
import { ReactComponent as MagnifyingGlass } from "../../assets/images/magnifyingGlass.svg";
import { ReactComponent as Chevron } from "../../assets/images/chevron.svg";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  loadFormValue,
  loadNewSelectOptions,
  loadValidationStates,
} from "../../actions/formDataActions";
//Utilities
import { validateForm } from "../../utilities/form/validation";

const FieldSelect = ({ pos }) => {
  //Store data
  const { selectOptions, formValues, settings } = useSelector(
    (state) => state.dataForm
  );
  const {
    name,
    label,
    placeholder,
    textOne,
    textTwo,
    position,
    validated,
    defaultValue,
  } = useSelector((state) => state.dataForm.settings.formContent[pos]);

  //States
  const [valuePlaceholder, setValuePlaceholder] = useState(placeholder);
  const [displayError, setDisplayError] = useState(false);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));
  const storeLoadNewSelectOptions = (newOptions) =>
    dispatch(loadNewSelectOptions(newOptions, position));
  const storeLoadValidationStates = (validationResult) =>
    dispatch(loadValidationStates(validationResult));

  //Functions
  const loadInputValue = (e) => {
    //Loads input value in store
    storeLoadFormValue([e.target.dataset.name, e.target.dataset.optionvalue]);
    setValuePlaceholder(e.target.dataset.optionvalue);
    //upload store validation state
    const validation = validateForm(settings.formContent, formValues);
    storeLoadValidationStates(validation.validationResult);
  };

  function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const filterSelectOptions = (e) => {
    //Loads new select options according to search field value
    const searchTerm = e.target.value;
    const newOptions = [];
    if (selectOptions && selectOptions[position]) {
      // eslint-disable-next-line
      selectOptions[position].originalOptions.map((originalOption) => {
        if (removeAccents(originalOption.name.toLowerCase()).includes(removeAccents(searchTerm.toLowerCase()))) {
          newOptions.push(originalOption);
        }
      });
    }
    storeLoadNewSelectOptions(newOptions);
  };

  const isCurrent = (option) => {
    //Load style if item is selected
    if (option === formValues[name]) {
      return " current";
    } else {
      return " ";
    }
  };

  //useEffect
  useEffect(() => {
    if (!validated) {
      setDisplayError(true);
    } else {
      setDisplayError(false);
    }
    // eslint-disable-next-line
  }, [validated]);

  useEffect(() => {
    //In case of update, load current value into form values
    if (defaultValue) {
      storeLoadFormValue([name, defaultValue]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="input-container select">
      {label && <div className="input-label">{label}</div>}
      {textOne && <div className="input-text">{textOne}</div>}
      {textTwo && <div className="input-text">{textTwo}</div>}
      <>
        {/* Display collapsed */}
        <div className="input-select-collapsed">
          <input
            type="text"
            disabled
            placeholder={valuePlaceholder}
            className={displayError ? "errorInput" : ""}
            defaultValue={defaultValue ? defaultValue : ""}
          />
          <Chevron />
        </div>
        {/* Display expanded */}
        <div className="input-select-expanded">
          <div className="input-select-expanded__container">
            {/* fixed search */}
            <div className="input-select-expanded__search">
              <input type="text" onChange={filterSelectOptions} />
              <MagnifyingGlass />
            </div>
            <div className="input-select-expanded__option-container">
              {/* Filter - Select - Options - API */}
              {selectOptions[position] &&
                selectOptions[position].workOptions.map((item, index) => (
                  <div
                    className={
                      "input-select-expanded__option " + isCurrent(item.name)
                    }
                    key={index}
                    data-name={name}
                    data-optionvalue={item.name}
                    onClick={loadInputValue}
                  >
                    {item.name}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default FieldSelect;
