import { LOAD_SETTINGS } from "../../types/formData";
import { LOAD_SELECT_OPTIONS } from "../../types/formData";
import { LOAD_INPUT_VALUE } from "../../types/formData";
import { DELETE_INPUT_VALUE } from "../../types/formData";
import { LOAD_NEW_SELECT_OPTIONS } from "../../types/formData";
import { LOAD_VALIDATION_RESULTS } from "../../types/formData";
import { ADD_VARIABLE_ROW } from "../../types/formData";
import { REMOVE_VARIABLE_ROW } from "../../types/formData";
import { ADD_VARIABLEROW_VALUE } from "../../types/formData";
import { ADD_VARIABLEROW_ROOM } from "../../types/formData";
import { REMOVE_VARIABLEROW_VALUE } from "../../types/formData";
import { LOAD_VROWS_VALIDATION_RESULTS } from "../../types/formData";
import { LOAD_VROW_VALUE_VALIDATION } from "../../types/formData";
import { LOAD_DEFAULT_VROW_VALIDATION } from "../../types/formData";
import { REMOVE_VROW_VALIDATION } from "../../types/formData";
import { LOAD_INITIAL_VROWS_VALUES } from "../../types/formData";
import { LOAD_NESTED_MUTATION } from "../../types/formData";
import { LOAD_GARMENT_DEFAULT } from "../../types/formData";
import { LOAD_GARMENT_VALUE } from "../../types/formData";
import { LOAD_ORDER } from "../../types/formData";
import { LOAD_MODAL_SELECT_PEDIDOROWS } from "../../types/formData";

const initialState = {
  settings: {},
  selectOptions: [],
  formValues: {},
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case LOAD_SELECT_OPTIONS:
      let currentOptions = state.selectOptions;
      currentOptions[action.payload.position] = action.payload.options;
      return {
        ...state,
        selectOptions: currentOptions,
      };
    case LOAD_INPUT_VALUE:
      let currentFormValues = state.formValues;
      currentFormValues[action.payload[0]] = action.payload[1];
      return {
        ...state,
        formValues: currentFormValues,
      };
    case DELETE_INPUT_VALUE:
      let oldFormValues = state.formValues;
      delete oldFormValues[action.key];
      return {
        ...state,
        formValues: oldFormValues,
      };
    case LOAD_NEW_SELECT_OPTIONS:
      const newOptions = state.selectOptions;
      newOptions[action.payload.position].workOptions = action.payload.options;
      return {
        ...state,
        selectOptions: newOptions,
      };
    case LOAD_VALIDATION_RESULTS:
      let currentValidationValues = state.settings.formContent;
      if (action.payload) {
        // eslint-disable-next-line
        action.payload.map((value, index) => {
          currentValidationValues[index].validated = value[1];
        });
      }
      return {
        ...state,
        settings: {
          ...state.settings,
          formContent: currentValidationValues,
        },
      };
    case ADD_VARIABLE_ROW:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [state.settings.variableRows.entity]: {
            ...state.formValues[state.settings.variableRows.entity],
            create: [
              ...state.formValues[state.settings.variableRows.entity].create,
              {},
            ],
          },
        },
      };
    case REMOVE_VARIABLE_ROW:
      const currentVariableRows =
        state.formValues[state.settings.variableRows.entity].create;

      let newVariableRows = [];
      for (let n = 0; n < currentVariableRows.length; n++) {
        if (n !== action.payload) newVariableRows.push(currentVariableRows[n]);
      }

      return {
        ...state,
        formValues: {
          ...state.formValues,
          [state.settings.variableRows.entity]: {
            ...state.formValues[state.settings.variableRows.entity],
            create: newVariableRows,
          },
        },
      };
    case ADD_VARIABLEROW_ROOM:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [state.settings.variableRows.entity]: {
            ...state.formValues[state.settings.variableRows.entity],
            create: [
              ...state.formValues[state.settings.variableRows.entity].create,
              {},
            ],
          },
        },
      };
    case ADD_VARIABLEROW_VALUE:
      // valueSet pos
      let currentVariableRowsValues =
        state.formValues[state.settings.variableRows.entity].create;
      currentVariableRowsValues[action.pos][action.valueSet[0]] =
        action.valueSet[1];
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [state.settings.variableRows.entity]: {
            ...state.formValues[state.settings.variableRows.entity],
            create: currentVariableRowsValues,
          },
        },
      };
    case REMOVE_VARIABLEROW_VALUE:
      const allCurrentVariableRows =
        state.formValues[state.settings.variableRows.entity].create;

      let allNewVariableRows = [];

      for (let m = 0; m < allCurrentVariableRows.length; m++) {
        if (m !== action.pos) {
          allNewVariableRows.push(allCurrentVariableRows[m]);
        }
      }

      return {
        ...state,
        formValues: {
          ...state.formValues,
          [state.settings.variableRows.entity]: {
            create: allNewVariableRows,
          },
        },
      };
    case LOAD_VROWS_VALIDATION_RESULTS:
      return {
        ...state,
        variableRowValidations: action.payload,
      };
    case LOAD_VROW_VALUE_VALIDATION:
      let currentVariableRowValidations = [];

      if (state.variableRowValidations) {
        // eslint-disable-next-line
        state.variableRowValidations.map((vRowValidations, index) => {
          if (!currentVariableRowValidations[index]) {
            currentVariableRowValidations[index] = [];
          }
          // eslint-disable-next-line
          vRowValidations.map((vRowValidation) => {
            if (
              action.rowNumber === index &&
              vRowValidation[0] === action.name
            ) {
              currentVariableRowValidations[index].push([
                action.name,
                action.validationResult,
              ]);
            } else {
              currentVariableRowValidations[index].push([
                vRowValidation[0],
                vRowValidation[1],
              ]);
            }
          });
        });
      } else {
        return state;
      }
      return {
        ...state,
        variableRowValidations: currentVariableRowValidations,
      };
    case LOAD_DEFAULT_VROW_VALIDATION:
      if (!state.variableRowValidations) {
        return { ...state, variableRowValidations: [action.rowDefaultValues] };
      } else {
        return {
          ...state,
          variableRowValidations: [
            ...state.variableRowValidations,
            action.rowDefaultValues,
          ],
        };
      }
    case REMOVE_VROW_VALIDATION:
      //action.pos
      const currentVRValidations = state.variableRowValidations;
      let newVRValidations = [];

      // eslint-disable-next-line
      currentVRValidations.map((currentVRValidation, index) => {
        if (action.pos !== index) {
          newVRValidations.push(currentVRValidation);
        }
      });
      return {
        ...state,
        variableRowValidations: newVRValidations,
      };
    case LOAD_INITIAL_VROWS_VALUES:
      //action.entity action.rowsData
      let newVariableRowsFormValues = [];
      let newVariableRowsFormValidations = [];

      //Get variable rows field names
      const fieldNames = state.settings.variableRows.fieldGroups;

      // Prepare variableRow Form Values
      // eslint-disable-next-line
      action.rowsData.map((actionRowData, index) => {
        let rowData = {};
        // eslint-disable-next-line
        fieldNames.map((fieldName) => {
          if (fieldName.name) {
            rowData[fieldName.name] = actionRowData[fieldName.name];
          }
        });
        newVariableRowsFormValues.push(rowData);
      });

      // Prepare variableRow Form Validations
      for (let f = 0; f < action.rowsData.length; f++) {
        let rowValidation = [];
        // eslint-disable-next-line
        fieldNames.map((fieldName) => {
          if (fieldName.name) {
            rowValidation.push([fieldName.name, true]);
          }
        });
        newVariableRowsFormValidations.push(rowValidation);
      }

      return {
        ...state,
        formValues: {
          ...state.formValues,
          [action.entity]: { create: newVariableRowsFormValues },
        },
        variableRowValidations: newVariableRowsFormValidations,
      };
    case LOAD_NESTED_MUTATION:
      //action.nestedMutation
      return {
        ...state,
        nestedMutation: action.nestedMutation,
      };
    case LOAD_GARMENT_DEFAULT:
      //action.rowDefault
      const newGarmentDefault = state.formValues.garments;
      newGarmentDefault.push(action.rowDefault);
      return {
        ...state,
        formValues: {
          ...state.formValues,
          garments: newGarmentDefault,
        },
      };
    case LOAD_GARMENT_VALUE:
      //action.valuePair
      const newGarmentValues = state.formValues.garments;
      newGarmentValues[action.valuePair[0]][action.valuePair[1]] =
        action.valuePair[2];
      return {
        ...state,
        formValues: {
          ...state.formValues,
          garments: newGarmentValues,
        },
      };
    case LOAD_ORDER:
      //action.pedido
      return {
        ...state,
        pedido: action.pedido,
      };
      case LOAD_MODAL_SELECT_PEDIDOROWS:
      //action.pedido
      return {
        ...state,
        selectOptionRows: action.rows,
      };
    default:
      return state;
  }
}
