export function locationEliminarModal(item,entity){
    return {
      id: item.id,
      title: "Por favor, confirme que desea eliminar esta población",
      subtitle:
        "¡CUIDADO! Eliminar esta población ELIMINARÁ también TODOS los CENTROS DE TRABAJO situados en ellas. Esta acción no se puede deshacer.",
      texts: [
        {
          text: "Nombre de la población",
          value: item.name,
        },
        {
          text: "Provincia a la que pertenece",
          value: item.province.name,
        },
        {
          text: "País al que pertenece",
          value: item.province.country.name,
        },
        {
          text: "Cantidad de centros de trabajo",
          value: item.workplaces.length,
        },
      ],
      buttons: [
        {
          text: "Confirmar",
          action: "delete",
          classes: "unsafe",
          entity: entity,
          actionType: "mutation",
        },
        {
          text: "Cancelar",
          action: "closeModal",
          classes: "safe",
          entity: entity,
        },
      ],
    };
}