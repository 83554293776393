export function pedidoEliminarModal(item, entity) {
  return {
    id: item.id,
    title:
      "Por favor, confirme que desea eliminar este pedido de " +
      item.client.name,
    subtitle:
      "¡CUIDADO! Eliminar este pedido ELIMINARÁ también TODAS las FILAS de este pedido. Esta acción no se puede deshacer.",
    texts: [
      {
        text: "Número de pedido",
        value: item.order_number,
      },
      {
        text: "Cliente",
        value: item.client.name,
      },
      {
        text: "Fecha de creación",
        value: item.order_date,
      },
    ],
    buttons: [
      {
        text: "Confirmar",
        action: "delete",
        classes: "unsafe",
        entity: entity,
        actionType: "mutation",
      },
      {
        text: "Cancelar",
        action: "closeModal",
        classes: "safe",
        entity: entity,
      },
    ],
  };
}
