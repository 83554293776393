import React, { useState, useEffect } from "react";
//Styles
import "../../../assets/css/form.css";
//Cookie token
import cookie from "react-cookies";
//Router
import { useHistory, useParams } from "react-router-dom";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadFormSettings,
  loadSelectOptions,
  loadFormValue,
} from "../../../actions/formDataActions";
//Components
import Form from "../../formSections/Form";
import ModalCancelNewPedidoRow from "../../modal/ModalCancelNewPedidoRow";

const LineaPedidoCreate = () => {
  //Redirect if not logged in
  let history = useHistory();
  const token = cookie.load("usertk");

  //Get order id from url params
  let { id } = useParams();

  //Store data
  const { settings, formValues } = useSelector((state) => state.dataForm);

  //States
  const [displayForm, setDisplayForm] = useState(false);

  //API
  const GET_PRENDAS = gql`
    query getPrendas {
      getPrendas {
        id
        name
      }
    }
  `;

  const [getPrendas, { error, data }] = useLazyQuery(GET_PRENDAS);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadSettings = (defaultSettings) =>
    dispatch(loadFormSettings(defaultSettings));
  const storeLoadSelectOptions = (optionObject) =>
    dispatch(loadSelectOptions(optionObject));
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));

  //useEffect
  useEffect(() => {
    if (settings && settings !== {}) {
      setDisplayForm(true);
    } else {
      setDisplayForm(false);
    }
    // eslint-disable-next-line
  }, [settings]);

  useEffect(() => {
    if (data && !error) {
      //Section Settings
      const defaultSettings = {
        id: id,
        entity: "pedidoRow",
        queryName: "createPedidoRow",
        title: "Crear línea para el pedido - " + id,
        text: 'Por favor, rellene los campos necesarios para añadir un nuevo trabajador y las prendas que se le piden. Puede pulsar "Añadir nueva prenda" para añadir una nueva prenda para el trabajador. Después pulse en aceptar para que los cambios se guarden.',
        formContent: [
          {
            type: "fieldText",
            name: "worker_name",
            size: 20,
            label: "Nombre del trabajador",
            placeholder: "Escriba el nombre del empleado *",
            required: true,
            validated: true,
            validation: "notEmpty",
          },
          {
            type: "fieldText",
            name: "job",
            size: 20,
            label: "Puesto",
            placeholder: "Escriba el puesto del trabajador *",
            required: true,
            validated: true,
            validation: "notEmpty",
          },
          {
            type: "fieldText",
            name: "workplace",
            size: 20,
            label: "Centro de trabajo",
            placeholder: "Escriba el C. de trabajo *",
            required: true,
            validated: true,
            validation: "notEmpty",
          },
          {
            type: "fieldText",
            name: "worker_code",
            size: 20,
            label: "Nº Interno",
            placeholder: "Escríbalo si lo tiene",
            validated: true,
          },
          {
            type: "fieldText",
            name: "dni",
            size: 20,
            label: "DNI",
            placeholder: "Escríbalo si lo tiene",
            validated: true,
          },
          {
            type: "fieldText",
            name: "sex",
            size: 20,
            label: "Sexo",
            placeholder: "Escríbalo si lo tiene",
            validated: true,
          },
          {
            type: "spacer",
            name: "spacer",
            size: 20,
            validated: true,
          },
          {
            type: "spacer",
            name: "spacer",
            size: 20,
            validated: true,
          },
          {
            type: "buttons",
            buttons: [
              {
                text: "Añadir nueva prenda",
                action: "addVariableRow",
                classes: "neutral",
                entity: "garment",
              },
              {
                text: "Crear",
                action: "save",
                classes: "safe",
                actionType: "save",
                entity: "pedidoRow",
                id: id,
              },
              {
                text: "Descartar",
                action: "link",
                link: "/ver-pedido",
                classes: "unsafe",
                entity: "pedidoRow",
              },
            ],
          },
        ],
        variableRows: {
          entity: "garments",
          initialRows: 1,
          fieldGroups: [
            {
              type: "fieldSelect",
              name: "prenda_id",
              size: 20,
              label: "Prenda",
              placeholder: "Seleccione una prenda *",
              values: data.getPrendas ? data.getPrendas : [],
              position: 0,
              required: true,
              validated: true,
              validation: "notEmpty",
            },
            {
              type: "fieldText",
              name: "size",
              size: 20,
              label: "Talla",
              placeholder: "Escriba la talla de la prenda *",
              required: true,
              validated: true,
              validation: "notEmpty",
            },
            {
              type: "fieldText",
              name: "quantity",
              size: 20,
              label: "Cantidad",
              placeholder: "Escriba la cantidad pedida *",
              required: true,
              validated: true,
              validation: "notEmpty",
              isNumber: true,
            },
            {
              type: "buttons",
              size: 20,
              buttons: [
                {
                  text: "Eliminar prenda",
                  action: "removeVariableRow",
                  classes: "unsafe",
                  entity: "garment",
                },
              ],
            },
          ],
        },
      };

      storeLoadSettings(defaultSettings);

      storeLoadSelectOptions({
        position: 0,
        options: {
          queryName: Object.keys(data)[0],
          originalOptions: data.getPrendas,
          workOptions: data.getPrendas,
        },
      });
    }

    // eslint-disable-next-line
  }, [data, error]);

  useEffect(() => {
    getPrendas();
    //Load id value to store
    storeLoadFormValue(["pedido_id", parseInt(id)]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      settings &&
      settings.variableRows &&
      settings.variableRows.entity &&
      !formValues.garments
    ) {
      //Structure & First room for first and required variableRow
      const defaultVariableRows = {
        create: [{}],
      };
      storeLoadFormValue([settings.variableRows.entity, defaultVariableRows]);
    }
    // eslint-disable-next-line
  }, [settings]);

  return (
    <>
      {!token ? (
        history.push("/")
      ) : (
        <>
          <div className="table-container">{displayForm && <Form />}</div>
          <ModalCancelNewPedidoRow />
        </>
      )}
    </>
  );
};

export default LineaPedidoCreate;
