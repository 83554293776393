import React from "react";
//Redux
import { useSelector } from "react-redux";
//Notifications
import { toast } from "react-toastify";

const CreateOrderButtonContinue = ({ setDisplayThirdRow,setDisplaySecondRow }) => {
  //Redux Data
  const { formValues } = useSelector((state) => state.dataForm);

  //Functions
  const retriveAction = () => {
    if (
      formValues.name &&
      formValues.name !== "" &&
      formValues.workplace &&
      formValues.workplace !== "" &&
      formValues.job &&
      formValues.job !== ""
    ){
      setDisplayThirdRow(true);
      setDisplaySecondRow(false);
    }else{
        setDisplayThirdRow(false);
        const messageKO = `
            Por favor, asigne los campos obligatorios que le faltan: 
            ${!formValues.name ? "Nombre" : ""}
            ${!formValues.name && !formValues.workplace ? ", Centro de trabajo" : ""}
            ${formValues.name && !formValues.workplace ? "Centro de trabajo" : ""}
            ${(!formValues.name || !formValues.workplace) && !formValues.job ? ", Puesto" : ""}
            ${formValues.name && formValues.workplace && !formValues.job ? "Puesto" : ""}
            `;
        toast.error(messageKO + ".");
    }
  };
  return (
    <button className="button safe mt-24" style={{marginLeft: "60px"}} onClick={retriveAction}>
      Continuar con el siguiente paso
    </button>
  );
};

export default CreateOrderButtonContinue;
