import React from "react";
//Router redirect
import { useHistory } from "react-router-dom";

//Notifications
import { toast } from "react-toastify";

const PrendasCreateButtonCancel = () => {

  //Redirection
  let history = useHistory();

  //Functions
  const retriveAction = () => {
    const messageKO = "No se han hecho cambios en las prendas";
    const redirection = `/buscar-prenda`;

    //Notify KO
    toast.error(messageKO);

    //Redirect
    history.push(redirection);
  };

  return (
    <button className="button unsafe" onClick={retriveAction}>
      Cancelar
    </button>
  );
};

export default PrendasCreateButtonCancel;
