import React, { useState, useEffect } from "react";
//Styles
import "../../../assets/css/form.css";
//Cookie token
import cookie from "react-cookies";
//Router redirect
import { useHistory, useParams } from "react-router-dom";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadFormSettings,
  loadSelectOptions,
  loadFormValue,
} from "../../../actions/formDataActions";
//Components
import SectionHeader from "../../tableSections/SectionHeader";
//Inputs
import FieldUploadPedido from "../pedidos/createOrder/createOrderInputs/FieldUploadPedido";
import FieldOrderNumber from "../pedidos/createOrder/createOrderInputs/FieldOrderNumber";
import FieldSelectTemplate from "../pedidos/createOrder/createOrderInputs/FieldSelectTemplate";
import FieldValueToAssign from "./createOrder/createOrderInputs/FieldValueToAssign";
import FieldsSetOrder from "./createOrder/createOrderInputs/FieldsSetOrder";
import OrderPrendaVariableRow from "./createOrder/createOrderInputs/OrderPrendaVariableRow";
//Buttons
import CreateOrderButtonContinue from "./createOrder/createOrderButtons/CreateOrderButtonContinue";
import CreateOrderButtonTemplate from "./createOrder/createOrderButtons/CreateOrderButtonTemplate";
//Preview
import OrderAssignPreview from "./createOrder/createOrderInputs/OrderAssignPreview";

const OrderCreateUploadFile = () => {
  //Redirect if not logged in
  let history = useHistory();
  const token = cookie.load("usertk");
  let { id } = useParams();

  //Store data
  const { settings, formValues } = useSelector((state) => state.dataForm);

  //States
  const [displayFirstRow, setDisplayFirstRow] = useState(true);
  const [isTemplate, setIsTemplate] = useState(true);
  const [displaySecondRow, setDisplaySecondRow] = useState(false);
  const [displayThirdRow, setDisplayThirdRow] = useState(false);
  const [importedObjectKeys, setImportedObjectKeys] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const exampleOrderRow = 0;

  //API
  const GET_ORDER_TEMPLATES = gql`
    query getOrderTemplates {
      getOrderTemplates {
        id
        name
        config
      }
    }
  `;

  const [getOrderTemplates, { error, data }] =
    useLazyQuery(GET_ORDER_TEMPLATES);

  //Section Settings
  let defaultSettings = {
    entity: "importarPedido",
    queryName: "createPedido",
    title: "Subir archivo de pedido",
    text: "Por favor, Seleccione el archivo del pedido que ha enviado el cliente y una plantilla si es necesario. Después pulse en aceptar para continuar al siguiente paso.",
  };

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadSettings = () => dispatch(loadFormSettings(defaultSettings));
  const storeLoadSelectOptions = (optionObject) =>
    dispatch(loadSelectOptions(optionObject));
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));

  //Functions

  //Count row object properties
  Object.size = function (obj) {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  //useEffect
  useEffect(() => {
    if (
      formValues &&
      formValues.order &&
      formValues.order.length > 0 &&
      formValues.order_number &&
      formValues.order_number !== ""
    ) {
      setDisplayFirstRow(false);
      //Set imported object keys
      setImportedObjectKeys(Object.keys(formValues.order[exampleOrderRow]));
      if(formValues.template_id){
      defaultSettings.title =
        "Previsualice el resultado de la importación del pedido " +
        formValues.order_number;
      defaultSettings.text =
        "Pulse el botón para revisar que el resultado sea el deseado.";
      }else{
        defaultSettings.title =
        "Asigne los campos según desee para el pedido " +
        formValues.order_number;
      defaultSettings.text =
        "En la parte de arriba puede ver los campos del archivo del pedido, en la parte de abajo los campos necesarios y un selector para asignar cuál es cuál.";  
      }
      storeLoadSettings(defaultSettings);
      setDisplaySecondRow(true);
    } else {
      setDisplayFirstRow(true);
      setDisplaySecondRow(false);
    }
    // eslint-disable-next-line
  }, [formValues.order, formValues.order_number, formValues.template_id]);

  useEffect(() => {
    if (data && !error) {
      storeLoadSelectOptions({
        position: 0,
        options: {
          queryName: Object.keys(data)[0],
          originalOptions: data.getOrderTemplates,
          workOptions: data.getOrderTemplates,
        },
      });
    }

    // eslint-disable-next-line
  }, [data, error]);

  useEffect(() => {
    storeLoadSettings();
    getOrderTemplates();
    storeLoadFormValue(["client", parseInt(id)]);
    storeLoadFormValue(["garments", []]);
    storeLoadFormValue([
      "order_date",
      new Date().toISOString().substring(0, 10),
    ]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (formValues && formValues.template_id && formValues.template_id !== 0) {
      setIsTemplate(true);
    } else {
      setIsTemplate(false);
    }

    // eslint-disable-next-line
  }, [formValues.template_id]);

  return (
    <>
      {!token ? (
        history.push("/")
      ) : (
        <>
          <div className="table-container">
            <SectionHeader title={settings.title} text={settings.text} />
            {displayFirstRow && (
              <>
                <div className="form form__first-row">
                  <FieldOrderNumber />
                  <FieldSelectTemplate />
                  <FieldUploadPedido />
                </div>
              </>
            )}
            {isTemplate && formValues.order && <CreateOrderButtonTemplate setShowPreview={setShowPreview}/>}
            {displaySecondRow && !formValues.template_id && (
              <>
                <div className="input-label text-green">
                  Datos del archivo del cliente
                </div>
                <div className="form form__first-row bg-grey">
                  {importedObjectKeys.map((key) => (
                    <FieldValueToAssign
                      key={key}
                      name={key}
                      value={formValues.order[exampleOrderRow][key]}
                    />
                  ))}
                </div>
                <div className="input-label text-green mt-24">
                  Asignación de los datos del trabajador (Asigne TODOS los
                  campos posibles menos las prendas).
                </div>
                <div className="form form__first-row bg-light-grey">
                  <FieldsSetOrder importedObjectKeys={importedObjectKeys} />
                </div>
                {!displayThirdRow && (
                  <CreateOrderButtonContinue
                    setDisplayThirdRow={setDisplayThirdRow}
                    setDisplaySecondRow={setDisplaySecondRow}
                  />
                )}
              </>
            )}
            {displayThirdRow && !formValues.template_id && (
              <>
                <div className="input-label text-green mt-24">
                  Asignación de los datos de las prendas
                </div>
                <div className="form form__first-row mt-24">
                  <OrderPrendaVariableRow exampleOrderRow={exampleOrderRow} />
                </div>
              </>
            )}
            {((isTemplate && showPreview) || displayThirdRow) && (
              <div className="form form__first-row mt-24">
                <OrderAssignPreview exampleOrderRow={exampleOrderRow} />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default OrderCreateUploadFile;
