import React, { useState, useEffect } from "react";
//Icons svg
import { ReactComponent as Chevron } from "../../assets/images/chevron.svg";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { loadCurrentPage, reloadResults } from "../../actions/tableDataActions";

const TablePagination = () => {
  //Store data
  const { paginatorItems, settings } = useSelector((state) => state.dataTable);

  //States
  const [page, setPage] = useState(1);

  //Redux actions
  const dispatch = useDispatch();
  const storeReloadResults = (config) => dispatch(reloadResults(config));
  const storeLoadCurrentPage = (page) => dispatch(loadCurrentPage(page));

  //Functions
  const setNewPage = (e) => {
    if (e.target.dataset.page && e.target.dataset.page !== "...") {
      if (e.target && e.target.dataset && e.target.dataset.page) {
        if (e.target.dataset.page === "next") {
          setPage(page + 1);
        } else if (e.target.dataset.page === "prev") {
          setPage(page - 1);
        } else {
          setPage(parseInt(e.target.dataset.page));
        }
      }
    }
  };

  //useEffect
  // Loads API options for this filter
  useEffect(() => {
    if (page !== settings.paginationSettings.page) {
      // Changes Store current Page
      storeLoadCurrentPage(page);

      //Triggers content reload
      storeReloadResults(true);
    }
    // eslint-disable-next-line
  }, [page]);

  return (
    <div className="table-pagination">
      {/* Previous arrow */}
      {paginatorItems && settings.paginationSettings.page !== 1 && (
        <div className="arrow-prev">
          <Chevron />
          <div
            className="chevron-hitpoint"
            data-page="prev"
            onClick={setNewPage}
          ></div>
        </div>
      )}
      {paginatorItems &&
        paginatorItems.length > 1 &&
        paginatorItems.map((page) => (
          <div
            key={`"page-${page}"`}
            className={
              page === settings.paginationSettings.page
                ? "currentPage"
                : "" + page === "..."
                ? "pageNoClickable"
                : ""
            }
            data-page={`${page}`}
            onClick={setNewPage}
          >
            {page}
          </div>
        ))}
      {/* Next arrow */}
      {paginatorItems &&
        settings.paginationSettings.page !== paginatorItems.length && (
          <div className="arrow-next">
            <Chevron />
            <div
              className="chevron-hitpoint"
              data-page="next"
              onClick={setNewPage}
            ></div>
          </div>
        )}
    </div>
  );
};

export default TablePagination;
