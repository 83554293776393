import React, { useEffect } from "react";
//Styles
import "../../../../assets/css/form.css";
//Components
import SelectCountry from "../workplaces/workplacesInputs/SelectCountry";
import SelectProvince from "../workplaces/workplacesInputs/SelectProvince";
import SelectLocation from "../workplaces/workplacesInputs/SelectLocation";
import InputName from "../workplaces/workplacesInputs/InputName";
import InputAddress from "../workplaces/workplacesInputs/InputAddress";
import InputPostalCode from "../workplaces/workplacesInputs/InputPostalCode";
import ButtonUpdateWorkplace from "../workplaces/workplacesButtons/ButtonUpdateWorkplace";
import ButtonCancelWorkplace from "../workplaces/workplacesButtons/ButtonCancelWorkplace";
//Cookie token
import cookie from "react-cookies";
//Router redirect
import { useHistory, useParams } from "react-router-dom";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Redux
import { useDispatch, useSelector } from "react-redux";
//Actions
import { loadFormValue } from "../../../../actions/formDataActions";
import { loadSettings } from "../../../../actions/tableDataActions";

//Components
import SectionHeader from "../../../tableSections/SectionHeader";

const WorkplacesUpdate = () => {
  //Redirect if not logged in
  let history = useHistory();
  const token = cookie.load("usertk");
  let { id } = useParams();

  //Store data
  const { settings } = useSelector((state) => state.dataForm);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));
  const storeSettings = (defaultSettings) =>
    dispatch(loadSettings(defaultSettings));

  //API
  const GET_WORKPLACE_DATA = gql`
  query getClienteWorkplace {
    getCliente(id: ${id}){
      name
    }
    getClienteWorkplace(id: ${id}){
      id
      name
      address
      postal_code
      client {
        id
        name
      }
      country {
        id
        name
      }
      province {
        id
        name
      }
      location {
        id
        name
      }
    }
  }
`;

  const [getWorkplaceData, { error, data }] = useLazyQuery(GET_WORKPLACE_DATA);

  //useEffect
  useEffect(() => {
    getWorkplaceData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Results without error
    if (data && !error) {
      //Load workplace data to store
      storeLoadFormValue(["client_id", data.getClienteWorkplace.client.id]);
      //Load Workplace data to store
      storeLoadFormValue(["country_id", data.getClienteWorkplace.country.id]);
      storeLoadFormValue(["province_id", data.getClienteWorkplace.province.id]);
      storeLoadFormValue(["location_id", data.getClienteWorkplace.location.id]);
      storeLoadFormValue(["name", data.getClienteWorkplace.name]);
      storeLoadFormValue(["address", data.getClienteWorkplace.address]);
      storeLoadFormValue(["postal_code", data.getClienteWorkplace.postal_code]);
      const defaultSettings = {
        entity: "workplace",
        queryName: "updateWorkplace",
        title: `Editar centro de trabajo de ${data.getClienteWorkplace.client.name}`,
        text: "Modifique los datos necesarios y pulse en el botón Actualizar para guardar los cambios en el centro de trabajo.",
      };
      storeSettings(defaultSettings);
    }
    // eslint-disable-next-line
  }, [data, error]);

  return (
    <>
      {!token ? (
        history.push("/")
      ) : (
        <>
          {data && data.getClienteWorkplace && (
            <div className="table-container">
              <SectionHeader title={settings.title} text={settings.text} />
              <div className="form form__first-row">
                <SelectCountry
                  defaultValue={data.getClienteWorkplace.country.name}
                />
                <SelectProvince
                  defaultValue={data.getClienteWorkplace.province.name}
                />
                <SelectLocation
                  defaultValue={data.getClienteWorkplace.location.name}
                />
              </div>
              <div className="form form__second-row">
                <InputName defaultValue={data.getClienteWorkplace.name} />
                <InputAddress defaultValue={data.getClienteWorkplace.address} />
                <InputPostalCode
                  defaultValue={data.getClienteWorkplace.postal_code}
                />
              </div>
              <div className="form form__button-row">
                <ButtonUpdateWorkplace />
                <ButtonCancelWorkplace />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default WorkplacesUpdate;
