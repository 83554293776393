import React, { useState, useEffect } from "react";
//Components
import Table from "../../tableSections/Table";
import Loading from "../../tableSections/Loading";
import ModalDeletePedido from "../../modal/ModalDeletePedido";
//Cookie token
import cookie from "react-cookies";
//Router
import { useParams } from "react-router-dom";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Notifications
import { toast } from "react-toastify";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadOriginalResults,
  loadWorkResults,
  loadSettings,
  initFilters,
  loadPaginatorItems,
  reloadResults,
} from "../../../actions/tableDataActions";
//Utilities
import { paginator, getPaginatorNavItems } from "../../../utilities/paginator";
import { getNewResults } from "../../../utilities/pedidosCliente";

const ClienteView = () => {
  //Redirect if not logged in
  const token = cookie.load("usertk");

  let { id } = useParams();
  //States
  const [displayTable, setDisplayTable] = useState(false);

  //API
  const GET_CLIENTE = gql`
  query getCliente {
    getCliente(id: ${id}) {
      id
      client_code
      name
      business_name
      address
      postal_code
      email
      phone
      discharge_date
      business_group {
        name
      }
      location {
        name
      }
      province {
        name
      }
      country {
        name
      }
    }
    getPedidosCliente(cliente_id: ${id}) {
      id
      order_date
      order_number
      isDeliveryNote
      isLabel
      isCount
      isReclamation
    }
  }
  `;

  const [getCliente, { error, data }] = useLazyQuery(GET_CLIENTE);

  //Store data
  const { reload, search, filters, originalData, settings, orderBy } =
    useSelector((state) => state.dataTable);

  //Redux actions
  const dispatch = useDispatch();
  const storeOriginalLoad = () => dispatch(loadOriginalResults(data));
  const storeWorkLoad = (paginatedResults) =>
    dispatch(loadWorkResults(paginatedResults));
  const storeSettings = (defaultSettings) =>
    dispatch(loadSettings(defaultSettings));
  const storeInitialFilters = (initalFilters) =>
    dispatch(initFilters(initalFilters));
  const loadNewPaginatorItems = (paginatorItems) =>
    dispatch(loadPaginatorItems(paginatorItems));
  const storeReloadResults = (config) => dispatch(reloadResults(config));
  const loadNewResults = (newResults) => dispatch(loadWorkResults(newResults));

  //Functions
  const goHome = () => {
    //Redirects if not logged in
    window.location.href = "/";
  };

  //useEffect
  useEffect(() => {
    // Results without error
    if (data && !error) {
      let processedCliente = {
        id: data.getCliente.id,
        client_code: data.getCliente.client_code,
        name: data.getCliente.name,
        business_name: data.getCliente.business_name,
        address: data.getCliente.address,
        postal_code: data.getCliente.postal_code,
        email: data.getCliente.email,
        phone: data.getCliente.phone,
        business_group: data.getCliente.business_group.name,
        country: data.getCliente.country.name,
        province: data.getCliente.province.name,
        location: data.getCliente.location.name,
        discharge_date: data.getCliente.discharge_date,
      };

      //Prepares settings
      //Section Settings
      const defaultSettings = {
        entity: "pedido",
        queryName: "getPedidosCliente",
        title: `Ver Cliente - ${processedCliente.name}`,
        text: "Breve descripción del cliente sobre particularidades, información relevante e histórico de pedidos.",
        info: [
          {
            label: "Fecha de alta",
            text: processedCliente.discharge_date,
            size: "10",
          },
          {
            label: "Código de Cliente",
            text: processedCliente.client_code,
            size: "15",
          },
          {
            label: "Razón Social",
            text: processedCliente.business_name,
            size: "25",
          },
          {
            label: "Grupo de empresas",
            text: processedCliente.business_group,
            size: "25",
          },
          {
            label: "Teléfono",
            text: processedCliente.phone,
            size: "10",
          },
          {
            label: "Email",
            text: processedCliente.email,
            size: "15",
          },
          {
            label: "Dirección",
            text: processedCliente.address,
            size: "25",
          },
          {
            label: "C. Postal",
            text: processedCliente.postal_code,
            size: "25",
          },
          {
            label: "Población",
            text: processedCliente.location,
            size: "25",
          },
          {
            label: "Provincia",
            text: processedCliente.province,
            size: "10",
          },
          {
            label: "País",
            text: processedCliente.country,
            size: "15",
          },
        ],
        createButtonText: "Crear Nuevo Pedido",
        createButtonLink: "/crear-pedido/seleccionar-cliente",
        tableHeaders: [
          { text: "Número", size: 15, orderBy: true, field: "order_number" },
          { text: "Fecha", size: 15, orderBy: true, field: "order_date" },
          {
            text: "Albarán",
            size: 11,
            orderBy: false,
            field: "isDeliveryNote",
            icon: true,
          },
          {
            text: "Etiquetas",
            size: 11,
            orderBy: false,
            field: "isDeliveryNote",
            icon: true,
          },
          {
            text: "Recuentos",
            size: 11,
            orderBy: false,
            field: "isDeliveryNote",
            icon: true,
          },
          {
            text: "Reclamaciones",
            size: 11,
            orderBy: false,
            field: "isDeliveryNote",
            icon: true,
          },
          {
            text: "Opciones disponibles",
            size: 25,
            orderBy: false,
            field: "options",
          },
        ],
        searchLabel: "Buscar pedido",
        filterSettings: [
          {
            name: "dateFrom",
            placeholder: "Desde...",
            queryname: "getPedidosCliente",
          },
          {
            name: "dateTo",
            placeholder: "Hasta...",
            queryname: "getPedidosCliente",
          },
        ],
        paginationSettings: { page: 1, perPage: 4 },
        rowContent: [
          { size: 15, content: "order_number", align: "left" },
          { size: 15, content: "order_date", align: "left" },
          { size: 11, icon: true, type: "isDeliveryNote" },
          { size: 11, icon: true, type: "isLabel" },
          { size: 11, icon: true, type: "isCount" },
          { size: 11, icon: true, type: "isReclamation" },
          {
            size: 25,
            buttons: [
              {
                text: "Ver",
                action: "link",
                link: "/ver-pedido",
                classes: "safe",
              },
              {
                text: "Editar",
                action: "link",
                link: "/editar-pedido",
                classes: "neutral",
              },
              { text: "Eliminar", action: "modal", classes: "unsafe" },
            ],
          },
        ],
      };
      //Load defaultSettings in store
      storeSettings(defaultSettings);

      const initalFilters = [
        {
          name: "dateFrom",
          value: "",
          index: 0,
        },
        {
          name: "dateTo",
          value: "",
          index: 0,
        },
      ];

      //Load initialFilters in store
      storeInitialFilters(initalFilters);
    } else if (data && data.getPedidosCliente.length === 0) {
      // No results message display
      const Msg = ({ closeToast, toastProps }) => (
        <div>
          No existe ningún resultado.
          <br />
          <br />
          Por favor, modifique la búsqueda o los filtros aplicados.
        </div>
      );
      toast.error(<Msg />);
    } else if (error) {
      // Technical error display
      const Msg = ({ closeToast, toastProps }) => (
        <div>
          Se ha producido un error técnico
          <br />
          <br />
          Por favor, contacte con nosotros en el email que encontrará al pie de
          esta página.
        </div>
      );
      console.log(error);
      toast.error(<Msg />);
    }
    // eslint-disable-next-line
  }, [data, error]);

  useEffect(() => {
    if (settings && data && !error) {
      // Gets paginator items
      const paginatorItems = getPaginatorNavItems(
        data.getPedidosCliente,
        settings.paginationSettings.perPage,
        settings.paginationSettings.page,
      );

      //Load work copy paginated
      let paginatedResults = {
        getPedidosCliente: paginator(
          data.getPedidosCliente,
          settings.paginationSettings.page,
          settings.paginationSettings.perPage
        ),
      };

      storeWorkLoad(paginatedResults); // Loads work copy in store
      //Load pagination items
      loadNewPaginatorItems(paginatorItems);

      // Loads original copy in store
      storeOriginalLoad(data);

      // Displays table
      setDisplayTable(true);
    }
    // eslint-disable-next-line
  }, [settings]);

  useEffect(() => {
    //Generates new content and pagination
    if (reload) {
      //reset load flag to false to avoid loop
      storeReloadResults(false);

      // Gets new results
      const newResults = getNewResults(originalData, search, filters, orderBy);

      // Gets paginator items
      const paginatorItems = getPaginatorNavItems(
        newResults.getPedidosCliente,
        settings.paginationSettings.perPage,
        settings.paginationSettings.page,
      );

      // Gets paginated results
      let paginatedResults = {
        getPedidosCliente: paginator(
          newResults.getPedidosCliente,
          settings.paginationSettings.page,
          settings.paginationSettings.perPage
        ),
      };
      //Load paginated results
      loadNewResults(paginatedResults);
      //Load pagination items
      loadNewPaginatorItems(paginatorItems);
    }
    // eslint-disable-next-line
  }, [reload]);

  useEffect(() => {
    //Runs query at first load
    getCliente();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!token ? (
        goHome()
      ) : (
        <>
          <div className="table-container">
            <Loading />
            {displayTable && <Table />}
          </div>
          <ModalDeletePedido />
        </>
      )}
    </>
  );
};

export default ClienteView;
