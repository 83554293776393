import React from "react";
//Router redirect
import { useHistory } from "react-router-dom";
//Notifications
import { toast } from "react-toastify";

const CreateOrderButtonCancel = () => {

  //Redirection
  let history = useHistory();

  //Functions
  const retriveAction = () => {
    toast.error("Se ha cancelado la creación del pedido");
    //Redirect
    history.push("/crear-pedido/seleccionar-cliente/");
  };
  return (
    <button className="button unsafe mt-24" onClick={retriveAction}>
      Cancelar
    </button>
  );
};

export default CreateOrderButtonCancel;
