import React, { useEffect } from "react";
//Styles
import "../../../../assets/css/form.css";
//Components
import SelectCountry from "../workplaces/workplacesInputs/SelectCountry";
import SelectProvince from "../workplaces/workplacesInputs/SelectProvince";
import SelectLocation from "../workplaces/workplacesInputs/SelectLocation";
import InputName from "../workplaces/workplacesInputs/InputName";
import InputAddress from "../workplaces/workplacesInputs/InputAddress";
import InputPostalCode from "../workplaces/workplacesInputs/InputPostalCode";
import ButtonCreateWorkplace from "./workplacesButtons/ButtonCreateWorkplace";
import ButtonCancelWorkplace from "./workplacesButtons/ButtonCancelWorkplace";
//Cookie token
import cookie from "react-cookies";
//Router redirect
import { useHistory, useParams } from "react-router-dom";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Redux
import { useDispatch, useSelector } from "react-redux";
//Actions
import { loadFormValue } from "../../../../actions/formDataActions";
import { loadSettings } from "../../../../actions/tableDataActions";

//Components
import SectionHeader from "../../../tableSections/SectionHeader";

const WorkplacesCreate = () => {
  //Redirect if not logged in
  let history = useHistory();
  const token = cookie.load("usertk");
  let { id } = useParams();

  //Store data
  const { settings, formValues } = useSelector((state) => state.dataForm);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));
  const storeSettings = (defaultSettings) =>
    dispatch(loadSettings(defaultSettings));

  //API
  const GET_CLIENT_NAME = gql`
  query getCliente {
    getCliente(id: ${id}){
      name
    }
  }
`;

  const [getClienteName, { error, data }] = useLazyQuery(GET_CLIENT_NAME);

  //useEffect
  useEffect(() => {
    //Get client name
    getClienteName();
    //Load client id
    storeLoadFormValue(["client_id", parseInt(id)]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Results without error
    if (data && !error) {
      const defaultSettings = {
        entity: "workplace",
        queryName: "createWorkplace",
        title: `Crear nuevo centro de trabajo de ${data.getCliente.name}`,
        text: "Rellene correctamente los campos obligatiorios (marcados con un asterisco) y pulse en el botón Crear para guardar el nuevo centro de trabajo.",
      };
      storeSettings(defaultSettings);
    }
    // eslint-disable-next-line
  }, [data, error]);

  return (
    <>
      {!token ? (
        history.push("/")
      ) : (
        <>
          <div className="table-container">
            <SectionHeader title={settings.title} text={settings.text} />
            <div className="form form__first-row">
              <SelectCountry />
              {formValues && formValues.country_id && <SelectProvince />}
              {formValues && formValues.province_id && <SelectLocation />}
            </div>

            <div className="form form__second-row">
              {formValues && formValues.location_id && (
                <>
                  <InputName />
                  <InputAddress />
                  <InputPostalCode />
                </>
              )}
            </div>
            <div className="form form__button-row">
            {formValues && formValues.location_id && <ButtonCreateWorkplace />}
            {formValues && formValues.location_id && <ButtonCancelWorkplace />}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WorkplacesCreate;
