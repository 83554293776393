import React, { useState, useEffect } from "react";
//Styles
import "../../../../assets/css/buscar-prenda.css";
//Components
import Table from "../../../tableSections/Table";
import Loading from "../../../tableSections/Loading";
import ModalGetWorkplace from "../../../modal/ModalGetWorkplace";
//Router
import { useParams } from "react-router-dom";
//Cookie token
import cookie from "react-cookies";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Notifications
import { toast } from "react-toastify";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadOriginalResults,
  loadWorkResults,
  loadSettings,
  initFilters,
  loadPaginatorItems,
  reloadResults,
} from "../../../../actions/tableDataActions";
//External Function
import { getNewResults } from "../../../../utilities/workplaces";
//Utilities
import {
  paginator,
  getPaginatorNavItems,
} from "../../../../utilities/paginator";

const WorkplacesView = () => {
  const token = cookie.load("usertk"); // Used to redirect not logged in access attempts.
  let { id } = useParams();

  //Store data
  const { reload, search, filters, originalData, settings, orderBy } =
    useSelector((state) => state.dataTable);

  const initalFilters = [
    {
      name: "location",
      value: "",
      index: 0,
    },
  ];

  //States
  const [displayTable, setDisplayTable] = useState(false);

  //API
  const GET_CLIENT_WORKPLACES = gql`
    query getClienteWorkplaces {
      getClienteWorkplaces(cliente_id:${id}){
        id
        name
        address
        postal_code
        client {
          name
        }
        location{
          id
          name
          province{
            name
            country{
              name
            }
          }
        }
      }
      getCliente(id: ${id}){
        name
      }
    }
  `;

  const [getClienteWorkplaces, { error, data }] = useLazyQuery(
    GET_CLIENT_WORKPLACES
  );

  //Functions
  const goHome = () => {
    //Redirects if not logged in
    window.location.href = "/";
  };

  //Redux actions
  const dispatch = useDispatch();
  const storeOriginalLoad = (processedData) =>
    dispatch(loadOriginalResults(processedData));
  const storeWorkLoad = (paginatedResults) =>
    dispatch(loadWorkResults(paginatedResults));
  const storeSettings = (defaultSettings) => dispatch(loadSettings(defaultSettings));
  const storeInitialFilters = () => dispatch(initFilters(initalFilters));
  const loadNewPaginatorItems = (paginatorItems) =>
    dispatch(loadPaginatorItems(paginatorItems));
  const storeReloadResults = (config) => dispatch(reloadResults(config));
  const loadNewResults = (newResults) => dispatch(loadWorkResults(newResults));

  //useEffect
  useEffect(() => {
    // Results without error
    if (data && !error) {
      //Section Settings
      const defaultSettings = {
        entity: "workplace",
        queryName: "getClienteWorkplaces",
        title: `Lista de centros de trabajo de ${data.getCliente.name}`,
        text: "Puede utilizar el buscador para encontrar un centro de trabajo por su nombre, dirección, código postal, país, provincia o población.",
        createButtonText: "Crear Nuevo Centro de Trabajo",
        createButtonLink: `/centros-de-trabajo/crear-centro-de-trabajo/${id}`,
        tableHeaders: [
          { text: "Centro de Trabajo", size: 20, orderBy: true, field: "name" },
          { text: "Dirección", size: 20, orderBy: true, field: "address" },
          { text: "C. Postal", size: 10, orderBy: false, field: "postal_code" },
          {
            text: "Población",
            size: 25,
            orderBy: false,
            field: "location.name",
          },
          {
            text: "Opciones disponibles",
            size: 25,
            orderBy: false,
            field: "options",
          },
        ],
        searchLabel: "Buscar Centro de trabajo",
        filterSettings: [
          {
            name: "location",
            placeholder: "Población",
            queryname: "getLocations",
          },
        ],
        paginationSettings: { page: 1, perPage: 6 },
        rowContent: [
          { size: 20, content: "name", align: "left" },
          { size: 20, content: "address", align: "left" },
          { size: 10, content: "postal_code", align: "left" },
          { size: 25, content: "location", subcontent: "name", align: "left" },
          {
            size: 25,
            buttons: [
              { text: "Ver", action: "modal", classes: "safe" },
              {
                text: "Editar",
                action: "link",
                link: "/centros-de-trabajo/editar-centro-de-trabajo",
                classes: "neutral",
              },
              { text: "Eliminar", action: "modal", classes: "unsafe" },
            ],
          },
        ],
      };
      storeSettings(defaultSettings);

      //Gets filter options
      let getLocations = getFilterOptions(data.getClienteWorkplaces, [
        "location",
        "name",
      ]);

      // Gets paginator items
      const paginatorItems = getPaginatorNavItems(
        data.getClienteWorkplaces,
        defaultSettings.perPage,
        defaultSettings.paginationSettings.page,
      );

      //Load work copy paginated
      let paginatedResults = {
        getLocations: getLocations,
        getClienteWorkplaces: paginator(
          data.getClienteWorkplaces,
          defaultSettings.paginationSettings.page,
          defaultSettings.paginationSettings.perPage
        ),
      };

      storeWorkLoad(paginatedResults); // Loads work copy in store
      //Load pagination items
      loadNewPaginatorItems(paginatorItems);

      // Loads original copy in store adding dynamic select options
      let processedData = {
        getClienteWorkplaces: data.getClienteWorkplaces,
        getLocations: getLocations,
      };

      storeOriginalLoad(processedData);

      // Displays table
      setDisplayTable(true);
    } else if (data && data.getClienteWorkplaces.length === 0) {
      // No results message display
      const Msg = ({ closeToast, toastProps }) => (
        <div>
          No existe ningún resultado.
          <br />
          <br />
          Por favor, modifique la búsqueda o los filtros aplicados.
        </div>
      );
      toast.error(<Msg />);
    } else if (error) {
      // Technical error display
      const Msg = ({ closeToast, toastProps }) => (
        <div>
          Se ha producido un error técnico
          <br />
          <br />
          Por favor, contacte con nosotros en el email que encontrará al pie de
          esta página.
        </div>
      );
      console.log(error);
      toast.error(<Msg />);
    }
    // eslint-disable-next-line
  }, [data, error]);

  useEffect(() => {
    //Generates new content and pagination
    if (reload) {
      //reset load flag to false to avoid loop
      storeReloadResults(false);

      // Gets new results
      const newResults = getNewResults(originalData, search, filters, orderBy);

      // Gets paginator items
      const paginatorItems = getPaginatorNavItems(
        newResults.getClienteWorkplaces,
        settings.paginationSettings.perPage,
        settings.paginationSettings.page,
      );

      // Gets paginated results
      let paginatedResults = {
        getLocations: newResults.getLocations,
        getClienteWorkplaces: paginator(
          newResults.getClienteWorkplaces,
          settings.paginationSettings.page,
          settings.paginationSettings.perPage
        ),
      };
      //Load paginated results
      loadNewResults(paginatedResults);
      //Load pagination items
      loadNewPaginatorItems(paginatorItems);
    }
    // eslint-disable-next-line
  }, [reload]);

  useEffect(() => {
    //Runs query at first load
    getClienteWorkplaces();
    storeInitialFilters(initalFilters);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!token ? (
        goHome()
      ) : (
        <>
          <div className="table-container">
            <Loading />
            {displayTable && <Table />}
          </div>
          <ModalGetWorkplace />
        </>
      )}
    </>
  );
};

export default WorkplacesView;

function getFilterOptions(results, keys) {
  //location, name
  let res = [];
  // eslint-disable-next-line
  results.map((result) => {
    if (res.length === 0 || !res.some((e) => e.name === result[keys[0].name])) {
      res.push({ name: result[keys[0]].name });
    }
  });
  return res;
}
