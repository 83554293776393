import React from "react";
import FieldSelectAssignment from "../createOrderInputs/FieldSelectAssignment";

const FieldsSetOrder = ({ importedObjectKeys }) => {
  const selectOptions = getSelectOptions(importedObjectKeys);
  const valuesToAssign = [
    { name: "name", value: "Nombre" },
    { name: "surname1", value: "Apellido 1" },
    { name: "surname2", value: "Apellido 2" },
    { name: "worker_code", value: "Cod. Trabajador" },
    { name: "dni", value: "DNI" },
    { name: "sex", value: "Sexo" },
    { name: "workplace", value: "Centro de trabajo" },
    { name: "job", value: "Puesto" },
  ];

  return (
    <>
      {valuesToAssign.map((name,index) => (
        <FieldSelectAssignment key={index} name={name} options={selectOptions} />
      ))}
    </>
  );
};

export default FieldsSetOrder;

function getSelectOptions(importedObjectKeys) {
  let res = [];
  // eslint-disable-next-line
  importedObjectKeys.map((key) => {
    res.push({ value: key });
  });
  return res;
}
