import React, { useState, useEffect } from "react";
//Styles
import "../../../assets/css/buscar-prenda.css";
//Components
import Table from "../../tableSections/Table";
import Loading from "../../tableSections/Loading";
import ModalPedidos from "../../modal/ModalPedidos";
//Cookie token
import cookie from "react-cookies";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Notifications
import { toast } from "react-toastify";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadOriginalResults,
  loadWorkResults,
  loadSettings,
  initFilters,
  loadPaginatorItems,
  reloadResults,
} from "../../../actions/tableDataActions";
//Utilities
import { getNewResults } from "../../../utilities/pedidos";
import { paginator, getPaginatorNavItems } from "../../../utilities/paginator";

const Pedidos = () => {
  const token = cookie.load("usertk"); // Used to redirect not logged in access attempts.
  //Store data
  const { reload, search, filters, originalData, settings, orderBy } =
    useSelector((state) => state.dataTable);

  //Section Settings
  const defaultSettings = {
    entity: "pedido",
    queryName: "getPedidos",
    title: "Buscar Pedidos",
    text: "Utilice el buscador para encontrar un Pedido. Puede buscar por número de pedido. En los resultados podrá ordenar o filtrar por fecha.",
    createButtonText: "Crear Nuevo Pedido",
    createButtonLink: "/crear-pedido/seleccionar-cliente",
    tableHeaders: [
      { text: "Número", size: 15, orderBy: true, field: "order_number" },
      { text: "Fecha", size: 20, field: "order_date" },
      {
        text: "Albarán",
        size: 10,
        orderBy: false,
        field: "isDeliveryNote",
        icon: true,
      },
      {
        text: "Etiquetas",
        size: 10,
        orderBy: false,
        field: "isDeliveryNote",
        icon: true,
      },
      {
        text: "Recuentos",
        size: 10,
        orderBy: false,
        field: "isDeliveryNote",
        icon: true,
      },
      {
        text: "Reclam.",
        size: 10,
        orderBy: false,
        field: "isDeliveryNote",
        icon: true,
      },
      {
        text: "Opciones disponibles",
        size: 25,
        orderBy: false,
        field: "options",
      },
    ],
    searchLabel: "Buscar pedido",
    filterSettings: [
      {
        name: "dateFrom",
        placeholder: "Desde...",
        queryname: "getPedidosCliente",
      },
      {
        name: "dateTo",
        placeholder: "Hasta...",
        queryname: "getPedidosCliente",
      },
    ],
    paginationSettings: { page: 1, perPage: 6 },
    rowContent: [
      { size: 15, content: "order_number", align: "left" },
      { size: 20, content: "order_date", align: "left" },
      { size: 10, icon: true, type: "isDeliveryNote" },
      { size: 10, icon: true, type: "isLabel" },
      { size: 10, icon: true, type: "isCount" },
      { size: 10, icon: true, type: "isReclamation" },
      {
        size: 25,
        buttons: [
          {
            text: "Ver",
            action: "link",
            link: "/ver-pedido",
            classes: "safe",
          },
          { text: "Eliminar", action: "modal", classes: "unsafe" },
        ],
      },
    ],
  };
  const initalFilters = [
    {
      name: "dateFrom",
      value: "",
      index: 0,
    },
    {
      name: "dateTo",
      value: "",
      index: 1,
    },
  ];

  //States
  const [displayTable, setDisplayTable] = useState(false);

  //API
  const GET_PEDIDOS = gql`
  query getPedidos {
    getPedidos {
      id
      order_number
      order_date
      isDeliveryNote
      isLabel
      isCount
      isReclamation
    }
  }
  `;

  const [getPedidos, { error, data }] = useLazyQuery(GET_PEDIDOS);

  //Functions
  const goHome = () => {
    //Redirects if not logged in
    window.location.href = "/";
  };

  //Redux actions
  const dispatch = useDispatch();
  const storeOriginalLoad = () => dispatch(loadOriginalResults(data));
  const storeWorkLoad = (paginatedResults) =>
    dispatch(loadWorkResults(paginatedResults));
  const storeSettings = () => dispatch(loadSettings(defaultSettings));
  const storeInitialFilters = () => dispatch(initFilters(initalFilters));
  const loadNewPaginatorItems = (paginatorItems) =>
    dispatch(loadPaginatorItems(paginatorItems));
  const storeReloadResults = (config) => dispatch(reloadResults(config));
  const loadNewResults = (newResults) => dispatch(loadWorkResults(newResults));

  //useEffect
  useEffect(() => {
    // Results without error
    if (data && !error) {
      // Gets paginator items
      const paginatorItems = getPaginatorNavItems(
        data.getPedidos,
        settings.paginationSettings.perPage,
        settings.paginationSettings.page,
      );

      //Load work copy paginated
      let paginatedResults = {
        getLocations: data.getLocations,
        getBusinessGroups: data.getBusinessGroups,
        getPedidos: paginator(
          data.getPedidos,
          settings.paginationSettings.page,
          settings.paginationSettings.perPage
        ),
      };

      storeWorkLoad(paginatedResults); // Loads work copy in store
      //Load pagination items
      loadNewPaginatorItems(paginatorItems);

      // Loads original copy in store
      storeOriginalLoad(data);

      // Displays table
      setDisplayTable(true);
    } else if (data && data.getPedidos.length === 0) {
      // No results message display
      const Msg = ({ closeToast, toastProps }) => (
        <div>
          No existe ningún resultado.
          <br />
          <br />
          Por favor, modifique la búsqueda o los filtros aplicados.
        </div>
      );
      toast.error(<Msg />);
    } else if (error) {
      // Technical error display
      const Msg = ({ closeToast, toastProps }) => (
        <div>
          Se ha producido un error técnico
          <br />
          <br />
          Por favor, contacte con nosotros en el email que encontrará al pie de
          esta página.
        </div>
      );
      console.log(error);
      toast.error(<Msg />);
    }
    // eslint-disable-next-line
  }, [data, error]);

  useEffect(() => {
    //Generates new content and pagination
    if (reload) {
      //reset load flag to false to avoid loop
      storeReloadResults(false);

      // Gets new results
      const newResults = getNewResults(originalData, search, filters, orderBy);

      // Gets paginator items
      const paginatorItems = getPaginatorNavItems(
        newResults.getPedidos,
        settings.paginationSettings.perPage,
        settings.paginationSettings.page,
      );

      // Gets paginated results
      let paginatedResults = {
        getLocations: newResults.getLocations,
        getBusinessGroups: newResults.getBusinessGroups,
        getPedidos: paginator(
          newResults.getPedidos,
          settings.paginationSettings.page,
          settings.paginationSettings.perPage
        ),
      };
      //Load paginated results
      loadNewResults(paginatedResults);
      //Load pagination items
      loadNewPaginatorItems(paginatorItems);
    }
    // eslint-disable-next-line
  }, [reload]);

  useEffect(() => {
    //Runs query at first load
    getPedidos();
    storeSettings();
    storeInitialFilters(initalFilters);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!token ? (
        goHome()
      ) : (
        <>
          <div className="table-container">
            <Loading />
            {displayTable && <Table />}
          </div>
          <ModalPedidos />
        </>
      )}
    </>
  );
};

export default Pedidos;
