import React from "react";
//Styles
import "../../assets/css/dashboard.css";
//Cookie token
import cookie from "react-cookies";
//Router redirect
import { useHistory } from "react-router-dom";

const Dashboard = () => {
  //Redirect if not logged in
  let history = useHistory();
  const token = cookie.load("usertk");

  //Functions
  const sendMeTo = (e) => {
    history.push(e.target.value);
  };

  return (
    <>
      {!token ? (
        history.push("/")
      ) : (
        <>
          <div className="dashboard">
          <div className="dashboard__texts">
              <div className="dashboard__texts-title">Por favor, escoja una opción</div>
              <div className="dashboard__texts-help">
              Seleccione una de las opciones disponibles más abajo para continuar con el programa.
              </div>
            </div>
            <div className="dashboard-container">
              <div className="dashboard-container__title">Clientes</div>
              <div className="login-container__text-before">
                Seleccione una de las opciones para trabajar con Clientes.
              </div>
              <div className="login-container__form-container">
                <button
                  className="login-container__form-field-create"
                  type="submit"
                  onClick={sendMeTo}
                  value="/crear-cliente"
                >
                  Crear Cliente
                </button>
                <button
                  className="login-container__form-field-search"
                  type="submit"
                  onClick={sendMeTo}
                  value="/buscar-cliente"
                >
                  Buscar Cliente
                </button>
              </div>
            </div>
            <div className="dashboard-container">
              <div className="dashboard-container__title">Prendas</div>
              <div className="login-container__text-before">
                Seleccione una de las opciones para trabajar con Prendas.
              </div>
              <div className="login-container__form-container">
                <button
                  className="login-container__form-field-create"
                  type="submit"
                  onClick={sendMeTo}
                  value="/crear-prenda"
                >
                  Crear Prenda
                </button>
                <button
                  className="login-container__form-field-search"
                  type="submit"
                  onClick={sendMeTo}
                  value="/buscar-prenda"
                >
                  Buscar Prenda
                </button>
              </div>
            </div>
            <div className="dashboard-container">
              <div className="dashboard-container__title">Pedidos</div>
              <div className="login-container__text-before">
                Seleccione una de las opciones para trabajar con Pedidos.
              </div>
              <div className="login-container__form-container">
                <button
                  className="login-container__form-field-create"
                  type="submit"
                  onClick={sendMeTo}
                  value="/crear-pedido/seleccionar-cliente"
                >
                  Crear Pedido
                </button>
                <button
                  className="login-container__form-field-search"
                  type="submit"
                  onClick={sendMeTo}
                  value="/buscar-pedido"
                >
                  Buscar Pedido
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
