import React, { useEffect } from "react";
//Styles
import "../../../assets/css/login.css";
//Redux
import { loadUserTokenAction } from "../../../actions/authActions";
import { useDispatch } from "react-redux";

//Cookie token
import cookie from "react-cookies";

//Router redirect
import { useHistory } from "react-router-dom";

const Logout = () => {
  //Redirect if not logged in
  let history = useHistory();
  const token = cookie.load("usertk");

  //Store Action Dispatch
  const dispatch = useDispatch();
  const storeTokenLoad = (res) => dispatch(loadUserTokenAction(res));

  //Functions
  const backtoApp = () => {
    window.location.href = "/";
  };

  //Use effect
  useEffect(() => {
    storeTokenLoad("");
    cookie.remove("usertk");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!token ? (
        history.push("/")
      ) : (
        <>
          <div className="login">
            <div className="login-container">
              <div className="login-container__title">Muchas gracias</div>
              <div className="login-container__text-before">
                Ya puede cerrar la página o pestaña del navegador, o si lo desea
                volver a acceder a la aplicación.
              </div>
              <div className="login-container__form-container">
                <button
                  className="login-container__form-field-submit"
                  type="submit"
                  onClick={backtoApp}
                  style={{ marginBottom: 0 }}
                >
                  Volver a acceder
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Logout;
