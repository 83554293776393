import React, { useState, useEffect } from "react";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadFormValue,
  loadValidationStates,
} from "../../../../../actions/formDataActions";
//Utilities
import { validateForm } from "../../../../../utilities/form/validation";
import * as XLSX from "xlsx";

const FieldUploadPedido = ({ pos }) => {
  //States
  const [displayError, setDisplayError] = useState(false);

  //Store data
  const { formValues, settings } = useSelector((state) => state.dataForm);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));
  const storeLoadValidationStates = (validationResult) =>
    dispatch(loadValidationStates(validationResult));

  //Functions
  const loadInputValue = (e) => {
    let hojas = [];
    const file = e.target.files[0];

    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = (e) => {
      var data = new Uint8Array(e.target.result);
      var workbook = XLSX.read(data, { type: "array" });

      workbook.SheetNames.forEach(function (sheetName) {
        //Data object
        var XL_row_object = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheetName],
          { defval: "" }
        );
        let cleanStr = JSON.stringify(XL_row_object);
        cleanStr = cleanStr.replace(/\s{2,}/g, " ");
        cleanStr = cleanStr.replace(/\t/g, " ");
        cleanStr = cleanStr
          .toString()
          .trim()
          .replace(/(\r\n|\n|\r)/g, "")
          .replaceAll('" ', '"')
          .replaceAll(' "', '"');
        const cleandData = JSON.parse(cleanStr);
        hojas.push({
          data: cleandData,
          sheetName,
        });
      });

      if (formValues.template_id === 6 || formValues.template_id === 7) {
        storeLoadFormValue(["order", hojas[1].data]);
      } else {
        storeLoadFormValue(["order", hojas[0].data]);
      }
    };
  };

  //useEffect
  useEffect(() => {
    if (formValues.image) {
      //upload store validation state
      const validation = validateForm(settings.formContent, formValues);
      storeLoadValidationStates(validation.validationResult);
    }

    // eslint-disable-next-line
  }, [formValues.image]);

  useEffect(() => {
    if (formValues && formValues.order && formValues.order.length > 0) {
      setDisplayError(false);
    } else {
      setDisplayError(true);
    }
    // eslint-disable-next-line
  }, [formValues.order]);

  return (
    <>
      {formValues && formValues.order_number && (
        <div className="input-container fileUpload select">
          <div className="">
            <div className="input-label ">Importar archivo de pedido</div>
            <input
              type="file"
              name={"order"}
              className="no-margin"
              placeholder="Seleccione el archivo del pedido del cliente"
              onChange={loadInputValue}
              classes={displayError ? "error" : ""}
            />
            <div className="input-text text-left">
              Formatos de archivo admitidos: XLS, XLSX, ODF, CSV. Tamaño máximo
              de archivo permitido: 6Mb.
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FieldUploadPedido;
