import React from 'react'


const PrintOrderFrameLoad = () => {
    return ( 
        <iframe id="documents-to-print" style={{height: 0,width: 0, position: "absolute"}} title="Emitir documentos">
           
        </iframe>
     );
}
 
export default PrintOrderFrameLoad;