export const LOAD_SETTINGS = 'LOAD_SETTINGS';
export const LOAD_SELECT_OPTIONS = 'LOAD_SELECT_OPTIONS';
export const LOAD_INPUT_VALUE = 'LOAD_INPUT_VALUE';
export const DELETE_INPUT_VALUE = 'DELETE_INPUT_VALUE';
export const LOAD_NEW_SELECT_OPTIONS = 'LOAD_NEW_SELECT_OPTIONS';
export const LOAD_VALIDATION_RESULTS = 'LOAD_VALIDATION_RESULTS';
export const ADD_VARIABLE_ROW = 'ADD_VARIABLE_ROW';
export const REMOVE_VARIABLE_ROW = 'REMOVE_VARIABLE_ROW';
export const ADD_VARIABLEROW_VALUE = 'ADD_VARIABLEROW_VALUE';
export const ADD_VARIABLEROW_ROOM = 'ADD_VARIABLEROW_ROOM';
export const REMOVE_VARIABLEROW_VALUE = 'REMOVE_VARIABLEROW_VALUE';
export const LOAD_VROWS_VALIDATION_RESULTS = 'LOAD_VROWS_VALIDATION_RESULTS';
export const LOAD_VROW_VALUE_VALIDATION = 'LOAD_VROW_VALUE_VALIDATION';
export const LOAD_DEFAULT_VROW_VALIDATION = 'LOAD_DEFAULT_VROW_VALIDATION';
export const REMOVE_VROW_VALIDATION = 'REMOVE_VROW_VALIDATION';
export const LOAD_INITIAL_VROWS_VALUES = 'LOAD_INITIAL_VROWS_VALUES';
export const LOAD_ORIGINAL_GARMENTS_IDS = 'LOAD_ORIGINAL_GARMENTS_IDS';
export const LOAD_NESTED_MUTATION = 'LOAD_NESTED_MUTATION';
export const LOAD_GARMENT_DEFAULT = 'LOAD_GARMENT_DEFAULT';
export const LOAD_GARMENT_VALUE = 'LOAD_GARMENT_VALUE';
export const LOAD_ORDER = 'LOAD_ORDER';
export const LOAD_MODAL_SELECT_PEDIDOROWS = 'LOAD_MODAL_SELECT_PEDIDOROWS';
