import React from "react";
//Components
import SectionHeader from "./SectionHeader";
import CreateButton from "./CreateButton";
import PrintButton from "./PrintButton";
import TableHeaders from "./TableHeaders";
import TableSearch from "./TableSearch";
import TableFilters from "./TableFilters";
import TableRow from "./TableRow";
import TablePagination from "./TablePagination";
//Redux
import { useSelector } from "react-redux";

const Table = () => {
  //Store data
  const { settings, workData } = useSelector((state) => state.dataTable);

  return (
    <>
      <SectionHeader
        title={settings.title}
        text={settings.text}
        info={settings.info}
      />
      <div className="table-container__parameters">
        <div className="table-container__left-ones">
          <TableSearch />
          {settings && settings.filterSettings && (
            // eslint-disable-next-line
            <div className="table-container__filters-fields">
              <div className="table__filter-text">Filtrar por:</div>
              {settings.filterSettings.map((f, index) => (
                <div className="table__filter-container" key={f + index}>
                  <TableFilters position={index} />
                </div>
              ))}
            </div>
          )}
        </div>
        {(settings.documentsButtonText || settings.createButtonText) && 
        <div className="table-container__right-ones">
          {settings.documentsButtonText && (
            <PrintButton
              text={settings.documentsButtonText}
              link={settings.documentsButtonLink}
            />
          )}
          <CreateButton
            text={settings.createButtonText}
            link={settings.createButtonLink}
          />
        </div>
        }
      </div>
      {workData && (
        <>
          <div className="table-container__table-container">
            {/* Table Headers */}
            <TableHeaders headers={settings.tableHeaders} />
          </div>
          {/* Table rows */}
          {workData[settings.queryName].length > 0 ? (
            <div className="table-row-container">
              {workData[settings.queryName].map((item, index) => (
                <TableRow item={item} index={index} key={index} />
              ))}
            </div>
          ) : (
            <div className="noResults">Lo sentimos, no hay resultados</div>
          )}
          {/* Table pagination */}
          <TablePagination />
        </>
      )}
    </>
  );
};

export default Table;
