import React, { useState, useEffect } from "react";
//Styles
import "../../../assets/css/form.css";
//Cookie token
import cookie from "react-cookies";
//Router
import { useHistory, useParams } from "react-router-dom";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadFormSettings,
  loadSelectOptions,
  loadFormValue,
  loadVariableRowsCurrentValues,
  loadNestedMutation
} from "../../../actions/formDataActions";
//Components
import Form from "../../formSections/Form";
import ModalCancelNewPedidoRow from "../../modal/ModalCancelNewPedidoRow";

const LineaPedidoUpdate = () => {
  //Redirect if not logged in
  let history = useHistory();
  const token = cookie.load("usertk");

  //Get order line id from url params
  let { id } = useParams();

  //Store data
  const { settings, formValues } = useSelector((state) => state.dataForm);

  //States
  const [displayForm, setDisplayForm] = useState(false);

  //API
  const GET_PEDIDO_ROW = gql`
    query getPedidoRow {
      getPedidoRow(id: ${id}){
        id
        worker_name
        worker_code
        dni
        workplace
        job
        sex
        pedido {
          id
        }
        garments {
          prenda {
            name
            id
          }
          id
          size
          quantity
        }
      }
      getPrendas {
        id
        name
      }
    }
  `;

  const [getPedidoRow, { error, data }] = useLazyQuery(GET_PEDIDO_ROW);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadSettings = (defaultSettings) =>
    dispatch(loadFormSettings(defaultSettings));
  const storeLoadSelectOptions = (optionObject) =>
    dispatch(loadSelectOptions(optionObject));
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));
  const storeLoadVariableRowsCurrentValues = (vRvalues,entity) => dispatch(loadVariableRowsCurrentValues(vRvalues,entity));
  const storeLoadNestedMutation = (garmentIDs) => dispatch(loadNestedMutation(garmentIDs));

  //useEffect
  useEffect(() => {
    if (settings && settings !== {}) {
      setDisplayForm(true);
    } else {
      setDisplayForm(false);
    }
    // eslint-disable-next-line
  }, [settings]);

  useEffect(() => {
    if (data && !error) {
      //Section Settings
      const defaultSettings = {
        id: id,
        mutationBackID: data.getPedidoRow.pedido.id,
        entity: "pedidoRow",
        queryName: "updatePedidoRow",
        title: "Editar línea para el pedido - " + data.getPedidoRow.pedido.id,
        text: 'Por favor, edite los campos necesarios para modificar el trabajador y las prendas que se piden en esta línea del pedido. Puede pulsar "Añadir nueva prenda" para añadir una nueva prenda para el trabajador. Después pulse en aceptar para que los cambios se guarden.',
        formContent: [
          {
            type: "fieldText",
            name: "worker_name",
            size: 20,
            label: "Nombre del trabajador",
            placeholder: "Escriba el nombre del empleado *",
            required: true,
            validated: true,
            validation: "notEmpty",
            defaultValue: data.getPedidoRow.worker_name,
          },
          {
            type: "fieldText",
            name: "job",
            size: 20,
            label: "Puesto",
            placeholder: "Escriba el puesto del trabajador *",
            required: true,
            validated: true,
            validation: "notEmpty",
            defaultValue: data.getPedidoRow.job,
          },
          {
            type: "fieldText",
            name: "workplace",
            size: 20,
            label: "Centro de trabajo",
            placeholder: "Escriba el C. de trabajo *",
            required: true,
            validated: true,
            validation: "notEmpty",
            defaultValue: data.getPedidoRow.workplace,
          },
          {
            type: "fieldText",
            name: "worker_code",
            size: 20,
            label: "Nº Interno",
            placeholder: "Escríbalo si lo tiene",
            validated: true,
            defaultValue: data.getPedidoRow.worker_code,
          },
          {
            type: "fieldText",
            name: "dni",
            size: 20,
            label: "DNI",
            placeholder: "Escríbalo si lo tiene",
            validated: true,
            defaultValue: data.getPedidoRow.dni,
          },
          {
            type: "fieldText",
            name: "sex",
            size: 20,
            label: "Sexo",
            placeholder: "Escríbalo si lo tiene",
            validated: true,
            defaultValue: data.getPedidoRow.sex,
          },
          {
            type: "spacer",
            name: "spacer",
            size: 20,
            validated: true,
          },
          {
            type: "spacer",
            name: "spacer",
            size: 20,
            validated: true,
          },
          {
            type: "buttons",
            buttons: [
              {
                text: "Añadir nueva prenda",
                action: "addVariableRow",
                classes: "neutral",
                entity: "garment",
              },
              {
                text: "Actualizar",
                action: "update",
                classes: "safe",
                actionType: "update",
                entity: "pedidoRow",
                id: id,
              },
              {
                text: "Descartar cambios",
                action: "link",
                link: "/ver-pedido/",
                classes: "unsafe",
                entity: "pedidoRow",
              },
            ],
          },
        ],
        variableRows: {
          entity: "garments",
          initialRows: 1,
          fieldGroups: [
            {
              type: "fieldSelect",
              name: "prenda_id",
              size: 20,
              label: "Prenda",
              placeholder: "Seleccione una prenda *",
              values: data.getPrendas ? data.getPrendas : [],
              position: 0,
              required: true,
              validated: true,
              validation: "notEmpty",
            },
            {
              type: "fieldText",
              name: "size",
              size: 20,
              label: "Talla",
              placeholder: "Escriba la talla de la prenda *",
              required: true,
              validated: true,
              validation: "notEmpty",
            },
            {
              type: "fieldText",
              name: "quantity",
              size: 20,
              label: "Cantidad",
              placeholder: "Escriba la cantidad pedida *",
              required: true,
              validated: true,
              validation: "notEmpty",
              isNumber: true,
            },
            {
              type: "buttons",
              size: 20,
              buttons: [
                {
                  text: "Eliminar prenda",
                  action: "removeVariableRow",
                  classes: "unsafe",
                  entity: "garment",
                },
              ],
            },
          ],
        },
      };

      storeLoadSettings(defaultSettings);

      storeLoadSelectOptions({
        position: 0,
        options: {
          queryName: Object.keys(data)[0],
          originalOptions: data.getPrendas,
          workOptions: data.getPrendas,
        },
      });

      const getProcessedData = () => {
        let res = [];
        // eslint-disable-next-line
        data.getPedidoRow.garments.map((garment) => {
          res.push(
            {
              prenda_id: parseInt(garment.prenda.id),
              size: garment.size,
              quantity: garment.quantity,
              name: garment.prenda.name,
            }
          )
        })
        return res;
      }

      const processedData = getProcessedData();

      storeLoadVariableRowsCurrentValues(processedData, "garments");

      //Get current garment ids
      let garmentIDs = [];
      // eslint-disable-next-line
      data.getPedidoRow.garments.map((garment) => {
        garmentIDs.push(parseInt(garment.id));
      })

      storeLoadNestedMutation(`
      updatePedidoRowDeletePrendas(
          input: {
            id:${id}
            garments: {
              delete: [${garmentIDs}]
            }
          }
        ){
          id
        }`);
    }

    // eslint-disable-next-line
  }, [data, error]);

  useEffect(() => {
    getPedidoRow();
    //Load id value to store
    storeLoadFormValue(["id", parseInt(id)]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      settings &&
      settings.variableRows &&
      settings.variableRows.entity &&
      !formValues.garments
    ) {
      //Structure & First room for first and required variableRow
      const defaultVariableRows = {
        create: [{}],
      };
      storeLoadFormValue([settings.variableRows.entity, defaultVariableRows]);
    }
    // eslint-disable-next-line
  }, [settings]);

  return (
    <>
      {!token ? (
        history.push("/")
      ) : (
        <>
          <div className="table-container">{displayForm && <Form />}</div>
          <ModalCancelNewPedidoRow />
        </>
      )}
    </>
  );
};

export default LineaPedidoUpdate;
