import React, { useEffect } from "react";
//Styles
import "../../../assets/css/form.css";
//Components
import SelectCountry from "../clientes/clientesInputs/SelectCountry";
import SelectProvince from "../clientes/clientesInputs/SelectProvince";
import SelectLocation from "../clientes/clientesInputs/SelectLocation";
import InputClientCode from "../clientes/clientesInputs/InputClientCode";
import SelectBusinessGroup from "../clientes/clientesInputs/SelectBusinessGroup";
import InputName from "../clientes/clientesInputs/InputName";
import InputBusinessName from "../clientes/clientesInputs/InputBusinessName";
import InputAddress from "../clientes/clientesInputs/InputAddress";
import InputPostalCode from "../clientes/clientesInputs/InputPostalCode";
import InputPhone from "../clientes/clientesInputs/InputPhone";
import InputEmail from "../clientes/clientesInputs/InputEmail";
import ButtonCreateCliente from "../clientes/clientesButtons/ButtonCreateCliente";
import ButtonCancelCliente from "../clientes/clientesButtons/ButtonCancelCliente";
//Cookie token
import cookie from "react-cookies";
//Router redirect
import { useHistory } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from "react-redux";
//Actions
import { loadSettings } from "../../../actions/tableDataActions";

//Components
import SectionHeader from "../../tableSections/SectionHeader";

const ClientesCreate = () => {
  //Redirect if not logged in
  let history = useHistory();
  const token = cookie.load("usertk");

  //Store data
  const { settings, formValues } = useSelector((state) => state.dataForm);

  //Redux actions
  const dispatch = useDispatch();
  const storeSettings = (defaultSettings) =>
    dispatch(loadSettings(defaultSettings));

  //useEffect
  useEffect(() => {
    const defaultSettings = {
      entity: "workplace",
      queryName: "createWorkplace",
      title: "Crear cliente",
      text: "Rellene correctamente los campos obligatiorios (marcados con un asterisco) y pulse en el botón Crear para guardar el nuevo cliente.",
    };
    storeSettings(defaultSettings);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!token ? (
        history.push("/")
      ) : (
        <>
          <div className="table-container">
            <SectionHeader title={settings.title} text={settings.text} />
            <div className="form form__first-row">
              <InputClientCode />
              <SelectBusinessGroup />
              <InputName />
              <InputBusinessName />
            </div>
            <div className="form form__second-row">
              <SelectCountry />
              {formValues && formValues.country_id && <SelectProvince />}
              {formValues && formValues.province_id && <SelectLocation />}
            </div>
            <div className="form form__first-row">
              <InputAddress />
              <InputPostalCode />
              <InputPhone />
              <InputEmail />
            </div>
            <div className="form form__button-row">
              <ButtonCreateCliente />
              <ButtonCancelCliente />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ClientesCreate;
