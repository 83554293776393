import React, { useState, useEffect } from "react";
//Redux
import { useSelector } from "react-redux";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";

const OrderGarmentRow = ({ pos, exampleOrderRow }) => {
  //Redux Data
  const { garments, order } = useSelector((state) => state.dataForm.formValues);
  const { prenda_id, sizeValue, quantity } = garments[pos];

  //States
  const [name, setName] = useState("- -");
  const [qty, setQty] = useState("- -");
  const [size, setSize] = useState("- -");

  //API
  const GET_PRENDAS_NAMES = gql`
    query getPrendas {
      getPrendas {
        id
        name
      }
    }
  `;

  const [getPrendas, { error, data }] = useLazyQuery(GET_PRENDAS_NAMES);

  //useEffect
  useEffect(() => {

    if (prenda_id !== 0) {
      if (data && !error) {

        //Get prenda name by id
        let prendaName = "";
        // eslint-disable-next-line
        data.getPrendas.map((prenda) => {
          if (parseInt(prenda.id) === prenda_id) {
            prendaName = prenda.name;
          }
        });
        setName(prendaName);
      }
    }
    if (sizeValue !== "") {
      const sizePreview = order[exampleOrderRow][sizeValue];
      setSize(sizePreview);
    }
    if (quantity !== 0 || quantity !== "") {
      const quantityPreview = order[exampleOrderRow][quantity];
      setQty(quantityPreview);
    }

    // eslint-disable-next-line
  }, [prenda_id, sizeValue, quantity, data]);

  useEffect(() => {
    getPrendas();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {qty !== "" && (
        <div className="order-preview__garment-cells-container">
          <p className="col-60">{name}</p>
          <p className="col-10">{size}</p>
          <p className="col-30">{qty}</p>
        </div>
      )}
    </>
  );
};

export default OrderGarmentRow;
