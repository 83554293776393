import React, { useState, useEffect } from "react";
//Styles
import "../../../assets/css/login.css";
//API
import { gql, useMutation } from "@apollo/client";
//Notifications
import { toast } from "react-toastify";

const QueryNewPassword = () => {
  //States
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("false");
  const [allowed, setAllowed] = useState(false);

  //API
  const QUERY_PASSWORD_RESET = gql`
  mutation forgotPassword {
    forgotPassword(input: {email: "${email}"}) {
      message
    }
  }`;

  // eslint-disable-next-line
  const [forgotPassword, { data, error }] = useMutation(QUERY_PASSWORD_RESET);

  //Functions

  const changeHandler = (e) => {
    setEmail(e.target.value);
  };
  const actionRetrieve = () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      email &&
      email !== "" &&
      re.test(String(email).toLowerCase()) &&
      (email.includes("@oroel.com") || email === "birdcom@oroelapp.com")
    ) {
      setAllowed(true);
    } else {
      setEmailError(true);
      toast.error("El usuario (su email) no es correcto.");
    }
  };

  //Use effect
  useEffect(() => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //check input error styles
    if (
      email !== "" &&
      re.test(String(email).toLowerCase()) &&
      (email.includes("@oroel.com") || email === "birdcom@oroelapp.com")
    ) {
      setEmailError("false");
    } else {
      setEmailError("true");
    }
  }, [email]);

  useEffect(() => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (allowed) {
      if (
        email !== "" &&
        re.test(String(email).toLowerCase()) &&
        (email.includes("@oroel.com") || email === "birdcom@oroelapp.com")
      ) {

        forgotPassword()
          .then(({ data }) => {
            //Notify
            toast.success("Se ha solicitado el cambio de contraseña.");
            toast.success("Por favor, revise su correo electrónico.");
          })
          .catch((e) => {
            //Notify KO
            toast.error(
              "Lo sentimos, el email indicado no figura como usuario autorizado."
            );
          });
        setAllowed(false);
      }
    }
    // eslint-disable-next-line
  }, [allowed, email]);

  return (
    <>
      <div className="login">
        <div className="login-container">
          <div className="login-container__title">
            Solicitar nueva contraseña
          </div>
          <div className="login-container__text-before">
            Por favor, escriba usuario (su dirección de correo) para continuar.
          </div>
          <div className="login-container__form-container">
            <div className="login-container__form-field-container">
              <div className="login-container__form-field-label">
                Usuario (correo electrónico)
              </div>
              <div className="login-container__form-field-input">
                <input
                  type="text"
                  onChange={changeHandler}
                  className={emailError !== "false" ? "errorInput" : ""}
                  placeholder={
                    emailError !== "false"
                      ? "Por favor, introduzca su usuario (su email)"
                      : ""
                  }
                />
              </div>
            </div>
            <button
              className="login-container__form-field-submit"
              type="submit"
              onClick={actionRetrieve}
            >
              Solicitar nueva contraseña
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QueryNewPassword;
