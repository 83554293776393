import React, { useState, useEffect } from "react";
//Styles
import "../../../../assets/css/buscar-prenda.css";
//Components
import Table from "../../../tableSections/Table";
import Loading from "../../../tableSections/Loading";
import ModalGetCountry from "../../../modal/ModalGetCountry";
//Cookie token
import cookie from "react-cookies";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Notifications
import { toast } from "react-toastify";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  loadOriginalResults,
  loadWorkResults,
  loadSettings,
  loadPaginatorItems,
  reloadResults,
} from "../../../../actions/tableDataActions";
//External Function
import { getNewResults } from "../../../../utilities/paises";
//Utilities
import {
  paginator,
  getPaginatorNavItems,
} from "../../../../utilities/paginator";

const Countries = () => {
  const token = cookie.load("usertk"); // Used to redirect not logged in access attempts.
  //Store data
  const { reload, search, filters, originalData, settings, orderBy } =
    useSelector((state) => state.dataTable);

  //States
  const [displayTable, setDisplayTable] = useState(false);

  //API
  const GET_COUNTRIES = gql`
    query getCountries {
      getCountries {
        id
        name
      }
    }
  `;

  const [getCountries, { error, data }] = useLazyQuery(GET_COUNTRIES);

  //Functions
  const goHome = () => {
    //Redirects if not logged in
    window.location.href = "/";
  };

  //Redux actions
  const dispatch = useDispatch();
  const storeOriginalLoad = () => dispatch(loadOriginalResults(data));
  const storeWorkLoad = (paginatedResults) =>
    dispatch(loadWorkResults(paginatedResults));
  const storeSettings = (defaultSettings) =>
    dispatch(loadSettings(defaultSettings));
  const loadNewPaginatorItems = (paginatorItems) =>
    dispatch(loadPaginatorItems(paginatorItems));
  const storeReloadResults = (config) => dispatch(reloadResults(config));
  const loadNewResults = (newResults) => dispatch(loadWorkResults(newResults));

  //useEffect
  useEffect(() => {
    // Results without error
    if (data && !error) {
      
      //Section Settings
      const defaultSettings = {
        entity: "country",
        queryName: "getCountries",
        title: "Lista de países",
        text: "Puede utilizar el buscador para encontrar un país por su nombre.",
        createButtonText: "Crear Nuevo País",
        createButtonLink: `/paises/crear-pais/`,
        tableHeaders: [
          { text: "Nombre del país", size: 75, orderBy: true, field: "name" },
          {
            text: "Opciones disponibles",
            size: 25,
            orderBy: false,
            field: "options",
          },
        ],
        searchLabel: "Buscar País",
        paginationSettings: { page: 1, perPage: 8 },
        rowContent: [
          { size: 75, content: "name", align: "left" },
          {
            size: 25,
            buttons: [
              { text: "Ver", action: "modal", classes: "safe" },
              {
                text: "Editar",
                action: "link",
                link: "/paises/editar-pais",
                classes: "neutral",
              },
              { text: "Eliminar", action: "modal", classes: "unsafe" },
            ],
          },
        ],
      };

      storeSettings(defaultSettings);

      // Gets paginator items
      const paginatorItems = getPaginatorNavItems(
        data.getCountries,
        defaultSettings.paginationSettings.perPage,
        defaultSettings.paginationSettings.page,
      );

      //Load work copy paginated
      let paginatedResults = {
        getCountriesType: data.getCountriesType,
        getCountries: paginator(
          data.getCountries,
          defaultSettings.paginationSettings.page,
          defaultSettings.paginationSettings.perPage
        ),
      };

      storeWorkLoad(paginatedResults); // Loads work copy in store
      //Load pagination items
      loadNewPaginatorItems(paginatorItems);

      // Loads original copy in store
      storeOriginalLoad(data);

      // Displays table
      setDisplayTable(true);
    } else if (data && data.getCountries.length === 0) {
      // No results message display
      const Msg = ({ closeToast, toastProps }) => (
        <div>
          No existe ningún resultado.
          <br />
          <br />
          No existe todavía ningún país en el sistema o ninguno que coincida con la búsqueda.
        </div>
      );
      toast.error(<Msg />);
    } else if (error) {
      // Technical error display
      const Msg = ({ closeToast, toastProps }) => (
        <div>
          Se ha producido un error técnico
          <br />
          <br />
          Por favor, contacte con nosotros en el email que encontrará al pie de
          esta página.
        </div>
      );
      console.log(error);
      toast.error(<Msg />);
    }

    // eslint-disable-next-line
  }, [data, error]);

  useEffect(() => {
    //Generates new content and pagination
    if (reload) {
      //reset load flag to false to avoid loop
      storeReloadResults(false);

      // Gets new results
      const newResults = getNewResults(originalData, search, filters, orderBy);

      // Gets paginator items
      const paginatorItems = getPaginatorNavItems(
        newResults.getCountries,
        settings.paginationSettings.perPage,
        settings.paginationSettings.page,
      );

      // Gets paginated results
      let paginatedResults = {
        getCountriesType: newResults.getCountriesType,
        getCountries: paginator(
          newResults.getCountries,
          settings.paginationSettings.page,
          settings.paginationSettings.perPage
        ),
      };
      //Load paginated results
      loadNewResults(paginatedResults);
      //Load pagination items
      loadNewPaginatorItems(paginatorItems);
    }
    // eslint-disable-next-line
  }, [reload]);

  useEffect(() => {
    //Runs query at first load
    getCountries();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!token ? (
        goHome()
      ) : (
        <>
          <div className="table-container">
            <Loading />
            {displayTable && <Table />}
          </div>
          <ModalGetCountry />
        </>
      )}
    </>
  );
};

export default Countries;
