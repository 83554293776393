export function provinceVerModal(item,entity){
    return {
      id: item.id,
      infoTitle: "Ver provincia",
      texts: [
        {
          text: "Nombre de la provincia",
          value: item.name,
        },
        {
          text: "País al que pertenece",
          value: item.country.name,
        },
      ],
      buttons: [
        {
          text: "Editar",
          action: "link",
          link: "/provincias/editar-provincia",
          classes: "neutral",
          entity: entity,
        },
        {
          text: "Cerrar",
          action: "closeModal",
          classes: "safe",
          entity: entity,
        },
      ],
    };
}