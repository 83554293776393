import { CONFIGURAR_TOKEN } from '../../types/auth';

const initialState = {
  usertk: "",
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case CONFIGURAR_TOKEN:
      return {
        ...state,
        usertk: action.payload
      }
    default:
      return state;
  }
}
