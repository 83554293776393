import { LOAD_ORIGINAL_RESULTS } from "../../types/tableData";
import { LOAD_WORK_RESULTS } from "../../types/tableData";
import { INIT_FILTERS } from "../../types/tableData";
import { ADD_FILTER } from "../../types/tableData";
import { REMOVE_FILTER } from "../../types/tableData";
import { ADD_SEARCH } from "../../types/tableData";
import { REMOVE_SEARCH } from "../../types/tableData";
import { ADD_ORDERBY } from "../../types/tableData";
import { LOAD_SETTINGS } from "../../types/tableData";
import { LOAD_FILTER_OPTIONS } from "../../types/tableData";
import { LOAD_PAGINATOR_ITEMS } from "../../types/tableData";
import { RELOAD_RESULTS } from "../../types/tableData";
import { LOAD_CURRENT_PAGE } from "../../types/tableData";
import { LOAD_ORDERBY_ITEMS } from "../../types/tableData";
import { LOAD_MODAL_CONTENT } from "../../types/tableData";
import { DELETE_TABLE_ITEM } from "../../types/tableData";
import { ADD_ROW_2PRINT } from "../../types/tableData";
import { REMOVE_ROW_2PRINT } from "../../types/tableData";

const initialState = {
  originalData: [],
  workData: [],
  filters: [],
  search: "",
  orderBy: [],
  settings: {},
  paginatorItems: [],
  reload: false,
  modal: false,
  rowsToPrint: [],
};
// eslint-disable-next-line
export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_ORIGINAL_RESULTS:
      return {
        ...state,
        originalData: action.payload,
      };
    case LOAD_WORK_RESULTS:
      return {
        ...state,
        workData: action.payload,
      };
    case INIT_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case ADD_FILTER:
    case REMOVE_FILTER:
      let currentFilters = state.filters;
      currentFilters[action.payload.index] = action.payload;
      return {
        ...state,
        filters: currentFilters,
      };
    case LOAD_FILTER_OPTIONS:
      let currentFilterWorkData = state.workData;
      currentFilterWorkData[action.payload.attrname] = action.payload.options;
      return {
        ...state,
        workData: currentFilterWorkData,
      };
    case ADD_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case REMOVE_SEARCH:
      return {
        ...state,
        search: "",
      };
    case ADD_ORDERBY:
      return {
        ...state,
        orderBy: [...state, action.payload],
      };
    case LOAD_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case LOAD_PAGINATOR_ITEMS:
      return {
        ...state,
        paginatorItems: action.payload,
      };

    case RELOAD_RESULTS:
      return {
        ...state,
        reload: action.payload,
      };

    case LOAD_CURRENT_PAGE:
      return {
        ...state,
        settings: {
          ...state.settings,
          paginationSettings: {
            ...state.settings.paginationSettings,
            page: action.payload,
          },
        },
        modal: false,
      };
    case LOAD_ORDERBY_ITEMS:
      return {
        ...state,
        orderBy: action.payload,
      };
    case LOAD_MODAL_CONTENT:
      return {
        ...state,
        modal: action.payload,
      };
    case DELETE_TABLE_ITEM:
      //action.id action.queryName
      if (action.queryName === "getPedido") {
        return {
          ...state,
          originalData: {
            ...state.originalData,
            [action.queryName]: {
              ...state.originalData[action.queryName],
              rows: state.originalData[action.queryName].rows.filter(
                (item) => item.id !== action.id
              ),
            },
          },
          workData: {
            ...state.workData,
            [action.queryName]: state.workData[action.queryName].filter(
              (item) => item.id !== action.id
            ),
          },
        };
      } else {
        return {
          ...state,
          originalData: {
            ...state.originalData,
            [action.queryName]: state.originalData[action.queryName].filter(
              (item) => item.id !== action.id
            ),
          },
          workData: {
            ...state.workData,
            [action.queryName]: state.workData[action.queryName].filter(
              (item) => item.id !== action.id
            ),
          },
        };
      }
    case ADD_ROW_2PRINT:
      //action.pos
      return {
        ...state,
        rowsToPrint: [...state.rowsToPrint, action.pos],
      };

    case REMOVE_ROW_2PRINT:
      //action.pos
      const oldRowsToPrint = state.rowsToPrint;
      const newRowsToPrint = [];
      // eslint-disable-next-line
      oldRowsToPrint.map((oldRow) => {
        if (oldRow !== action.pos) {
          newRowsToPrint.push(oldRow);
        }
      });
      return {
        ...state,
        rowsToPrint: newRowsToPrint,
      };

    default:
      return state;
  }
}
