import React from "react";
//Icons svg
import { ReactComponent as LogoOroel } from "../../../../assets/images/logo-oroel.svg";

const ReclamationTemplate = ({ pedido, result }) => {
  //Date strings
  const getDateString = () => {
    var meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    var diasSemana = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    var f = new Date();
    return (
      diasSemana[f.getDay()] +
      ", " +
      f.getDate() +
      " de " +
      meses[f.getMonth()] +
      " de " +
      f.getFullYear()
    );
  };

  return (
    <>
      {/* Delivery note heading */}
      <div
        id="delivery-note-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          padding: "2.5% 5%",
          borderBottom: "1px solid black",
        }}
      >
        <div
          id="delivery-note__heading-column"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div>
            <LogoOroel />
          </div>
          <div
            style={{
              fontFamily: "SF Pro Display",
              fontSize: "12px",
              fontWeight: 600,
              margin: "12px 0",
            }}
          >
            Confecciones Oroel S.A.
          </div>
          <div style={{ fontSize: "12px" }}>
            Calle Los Ángeles, 10 - Po. Centrovía 50.198
            La Muela (Zaragoza)
            <br />
            Tel. 976-14-92-60 - Fax. 976-14-92-52
            <br />
            E-Mail: comercial@oroel.com | Web: www.grupooroel.com
          </div>
        </div>
        <div
          id="delivery-note__heading-column"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "40%",
          }}
        >
          <div>
            <h1
              style={{
                marginBottom: 0,
                fontFamily: "SF Pro Display",
                fontSize: "30px",
              }}
            >
              RECLAMACIÓN Y CONFIRMACIÓN
            </h1>
          </div>
          <div
            style={{
              fontFamily: "SF Pro Display",
              fontWeight: 600,
              margin: "12px 0",
              fontSize: "12px",
            }}
          >
            {getDateString()}
          </div>
        </div>
      </div>
      {/* Worker data */}
      <div
        id="worker-data-container"
        style={{
          padding: "2.5% 5%",
          fontSize: "12px",
          borderBottom: "1px solid black",
        }}
      >
        <div
          style={{
            fontFamily: "SF Pro Display",
            fontWeight: 600,
            marginBottom: "2.5%",
          }}
        >
          1. Datos del trabajador:
        </div>
        <div style={{ display: "flex", gap: "5%" }}>
          <div style={{ width: "25%", display: "flex", gap: "5%" }}>
            <div style={{ width: "70%" }}>Código de empleado:</div>
            <div style={{ width: "25%", fontWeight: 600 }}>
              {result.worker_code}
            </div>
          </div>
          <div style={{ width: "30%", display: "flex", gap: "5%" }}>
            <div style={{ width: "35%" }}>Nombre:</div>
            <div style={{ width: "60%", fontWeight: 600 }}>
              {result.worker_name}
            </div>
          </div>
          <div style={{ width: "30%", display: "flex", gap: "5%" }}>
            <div style={{ width: "55%" }}>DNI:</div>
            <div style={{ width: "40%", fontWeight: 600 }}>{result.dni}</div>
          </div>
        </div>

        <div style={{ display: "flex", gap: "5%" }}>
          <div style={{ width: "40%", display: "flex", gap: "5%" }}>
            <div style={{ width: "70%" }}>Puesto:</div>
            <div style={{ width: "25%", fontWeight: 600 }}>{result.job}</div>
          </div>
          <div style={{ width: "15%", display: "flex", gap: "5%" }}>
            <div style={{ width: "35%" }}>Sexo:</div>
            <div style={{ width: "60%", fontWeight: 600 }}>{result.sex}</div>
          </div>
          <div style={{ width: "30%", display: "flex", gap: "5%" }}>
            <div style={{ width: "55%" }}>Centro de trabajo:</div>
            <div style={{ width: "40%", fontWeight: 600 }}>
              {result.workplace}
            </div>
          </div>
        </div>
      </div>
      {/* Document subject */}
      <div
        id="subject-data-container"
        style={{
          padding: "2.5% 5% 0 5%",
          fontSize: "12px",
          borderBottom: "1px solid black",
        }}
      >
        <div
          style={{
            fontFamily: "SF Pro Display",
            fontWeight: 600,
            marginBottom: "0%",
            width: "100%",
          }}
        >
          2. Motivo del documento:
        </div>
        <div style={{ display: "flex", gap: "5%" }}>
          <div style={{ width: "35%", display: "flex", gap: "5%" }}>
            <div style={{ width: "100%" }}>
              <p>
                Por favor, después de probarse esta ropa, indique en esta hoja
                si las tallas recibidas son correctas y entregue posteriormente
                esta hoja a su inmediato superior.
              </p>
            </div>
          </div>
          <div
            style={{
              width: "60%",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              gap: "1%",
            }}
          >
            <div style={{ width: "100%" }}>
              <p>
                ¿Desea confirmar las tallas recibidas o reclamar alguna
                incidencia?
              </p>
            </div>
            <div
              style={{
                width: "100%",
                fontWeight: 600,
                display: "flex",
                justifyContent: "flex-start",
                gap: "5%",
              }}
            >
              <div
                id="checkbox-em-1"
                style={{
                  border: "1px solid black",
                  width: "10px",
                  height: "10px",
                }}
              ></div>
              <div style={{ marginRight: "5%" }}>Confirmar las tallas.</div>
              <div
                id="checkbox-em-2"
                style={{
                  border: "1px solid black",
                  width: "10px",
                  height: "10px",
                }}
              ></div>
              <div style={{ marginRight: "5%" }}>Recalamar una incidencia.</div>
            </div>
          </div>
        </div>
      </div>
      {/* Rows Headers */}
      <div
        className="reclamation__rows-heading-container"
        style={{
          display: "flex",
          flexWrap: "wrap",
          padding: "0% 5% 2.5% 5%",
          borderBottom: "1px solid black",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ width: "50%" }}>
            <p
              style={{
                fontSize: "20px",
                lineHeight: "20px",
                fontWeight: "600",
              }}
            >
              Datos de las prendas entregadas
            </p>
          </div>
          <div style={{ width: "50%" }}>
            <p
              style={{
                fontSize: "20px",
                lineHeight: "20px",
                fontWeight: "600",
              }}
            >
              Incidencias
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "49%",
              display: "flex",
              fontSize: "12px",
              gap: "5%",
            }}
          >
            <div
              style={{
                width: "60%",
                fontFamily: "SF Pro Display",
                fontWeight: 600,
              }}
            >
              Prenda
            </div>
            <div
              style={{
                width: "20%",
                fontFamily: "SF Pro Display",
                fontWeight: 600,
              }}
            >
              Talla
            </div>
            <div
              style={{
                width: "10%",
                fontFamily: "SF Pro Display",
                fontWeight: 600,
              }}
            >
              Cant.
            </div>
          </div>
          <div
            style={{
              width: "49%",
              display: "flex",
              gap: "5%",
              fontSize: "12px",
            }}
          >
            <div
              style={{
                width: "5%",
                fontFamily: "SF Pro Display",
                fontWeight: 600,
              }}
            >
              Peq.
            </div>
            <div
              style={{
                width: "5%",
                fontFamily: "SF Pro Display",
                fontWeight: 600,
              }}
            >
              Gra.
            </div>
            <div
              style={{
                width: "5%",
                fontFamily: "SF Pro Display",
                fontWeight: 600,
              }}
            >
              Err.
            </div>
            <div
              style={{
                width: "5%",
                fontFamily: "SF Pro Display",
                fontWeight: 600,
              }}
            >
              Falta
            </div>
            <div
              style={{
                width: "5%",
                fontFamily: "SF Pro Display",
                fontWeight: 600,
              }}
            >
              Otros
            </div>
            <div
              id="spacer"
              style={{
                width: "30%",
              }}
            ></div>
            <div
              style={{
                width: "15%",
              }}
            >
              Talla OK
            </div>
          </div>
        </div>
      </div>
      {/* Rows Garments */}
      {result.garments.map((garment, index) => (
        <div
          className="label__rows-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            padding: "0% 2.5%",
            margin: "2% ",
            fontSize: "12px",
            justifyContent: "space-between",
            paddingBottom: "2.5%",
            borderBottom: `${index + 1 === result.garments.length ? "" : "1px solid #ccc"}`,
          }}
          key={"prenda" + index}
        >
          <div style={{ width: "49%", display: "flex", gap: "5%" }}>
            <div id="garment-name" style={{ width: "60%", fontWeight: 600, height: "35px" }}>
              {garment.prenda.name}
            </div>
            <div id="garment-size" style={{ width: "20%" }}>
              {garment.size}
            </div>
            <div id="garment-quantity" style={{ width: "10%" }}>
              {garment.quantity}
            </div>
          </div>
          <div style={{ width: "49%", display: "flex", gap: "5%" }}>
            <div id="reclamation-small" style={{ width: "5%" }}>
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  border: "1px solid black",
                }}
              ></div>
            </div>
            <div id="reclamation-big" style={{ width: "5%" }}>
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  border: "1px solid black",
                }}
              ></div>
            </div>
            <div id="reclamation-error" style={{ width: "5%" }}>
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  border: "1px solid black",
                }}
              ></div>
            </div>
            <div id="reclamation-miss" style={{ width: "5%" }}>
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  border: "1px solid black",
                }}
              ></div>
            </div>
            <div id="reclamation-others" style={{ width: "5%" }}>
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  border: "1px solid black",
                }}
              ></div>
            </div>
            <div id="reclamation-spacer" style={{ width: "30%" }}>
              <div
                style={{
                  width: "90%",
                  height: "10px",
                  borderBottom: "1px solid black",
                }}
              ></div>
            </div>
            <div id="reclamation-ok" style={{ width: "15%" }}>
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  border: "1px solid black",
                }}
              ></div>
            </div>
          </div>
          <div
            style={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            <div style={{ width: "100%", textAlign: "start", fontWeight: 600, fontSize: "12px" }}>
              Observaciones:
            </div>
            <div style={{width: "100%", border: "1px solid #ccc", height: "20px"}}></div>
          </div>
        </div>
      ))}
      {/* Delivery note footer */}
      <div
        id="reclamation-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          padding: "1% 5%",
          borderTop: "1px solid black",
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <div
          id="reclamation__heading-column"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            fontFamily: "SF Pro Display",
            width: "100%",
          }}
        >
          <div>
            <p
              style={{ fontWeight: 600, fontSize: "14px", lineHeight: "14px" }}
            >
              Reclamación de tallas:
            </p>
            <p style={{ fontSize: "12px", color: "#070707" }}>
              1. No haga intercambio de prendas con sus compañeros.
              <br />
              2. Señale con una X los defectos en la entrega en las prendas
              erróneas.
              <br />
              3. Introduzca por escrito en observaciones el problema que tiene.
              <br />
              4. En caso de error en las tallas, indique la talla de cada prenda
              correcta.
              <br />
              5. Entregue esta hoja debidamente rellena junto con la ropa
              defectuosa en el Almacén.
              <br />
              6. El plazo de la reclamación expira diez días naturales después
              de recibir el vestuario .
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReclamationTemplate;
