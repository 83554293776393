import React, { useState, useEffect } from "react";
//Styles
import "../../../../assets/css/print-order-count-workplaces.css";
//Redux
import { useSelector } from "react-redux";
//Components
import CountByWorkplaceTemplate from "../count/CountByWorkplaceTemplate";
import CountWorkplacesReady from "../count/CountWorkplacesReady";

const CountByWorkplacesTemplate = ({ setFrameTarjet }) => {
  //Store data
  const { rowsToPrint } = useSelector((state) => state.dataTable);
  const { pedido } = useSelector((state) => state.dataForm);

  //States
  const [showDocuments, setShowDocuments] = useState(false);
  const [results, setResults] = useState([]);
  const [paginatedResults, setPaginatedResults] = useState([]);

  //Functions
  const getRowsToPrint = () => {
    let res = [];
    if (rowsToPrint.length === 0) {
      res = pedido.rows;
    } else {
      // eslint-disable-next-line
      rowsToPrint.map((rowToPrint) => {
        res.push(pedido.rows[rowToPrint]);
      });
    }
    return res;
  };

  function chunkArray(myArray, chunk_size) {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      let myChunk = myArray.slice(index, index + chunk_size);
      // Do something if you want with the group
      tempArray.push(myChunk);
    }

    return tempArray;
  }

  //useEffect
  useEffect(() => {
    if (pedido) {
      setResults(getRowsToPrint());
    }
    // eslint-disable-next-line
  }, [pedido, rowsToPrint]);

  useEffect(() => {
    if (results && results.length > 0) {
      let newPagedResults = [];
      let allWorkplaces = [];
      let garmentsCounts = [];

      //Get all workplaces
      // eslint-disable-next-line
      results.map((result) => {
        //Get all workplaces
        //first workplace
        if (allWorkplaces.length === 0) {
          allWorkplaces.push({
            workplace: result.workplace,
            allGarments: [],
          });
        } else {
          //Check if workplace exists
          let containsWorkplace = false;
          //there are already some workplaces
          // eslint-disable-next-line
          allWorkplaces.map((workplace) => {
            //If is same workplace
            if (workplace.workplace === result.workplace) {
              containsWorkplace = true;
            }
          });
          //adds it if doesn´t exists
          if (!containsWorkplace) {
            allWorkplaces.push({
              workplace: result.workplace,
              allGarments: [],
            });
          }
        }
      });

      //Add all garments to each workplace
      // eslint-disable-next-line
      results.map((result) => {
        //Match workplace
        // eslint-disable-next-line
        allWorkplaces.map((workplace) => {
          //If is same workplace
          if (workplace.workplace === result.workplace) {
            // eslint-disable-next-line
            result.garments.map((garment) => {
              workplace.allGarments.push(garment);
            });
          }
        });
      });

      //Group & format each workplace garments
      // eslint-disable-next-line
      allWorkplaces.map((workplace) => {
        garmentsCounts = [];
        // eslint-disable-next-line
        workplace.allGarments.map((garment) => {
          if (garmentsCounts.length === 0) {
            //object per garment with array populated with size & quantity objects
            garmentsCounts.push({
              name: garment.prenda.name,
              sizes: [
                {
                  size: garment.size,
                  quantity: garment.quantity,
                },
              ],
            });
          } else {
            //Check with current saved garments
            let containsGarment = false;
            // eslint-disable-next-line
            garmentsCounts.map((garmentCount) => {
              //If same garment
              if (garmentCount.name === garment.prenda.name) {
                let containsSize = false;
                // eslint-disable-next-line
                garmentCount.sizes.map((size) => {
                  //if same size
                  if (size.size === garment.size) {
                    size.quantity = size.quantity + garment.quantity;
                    containsSize = true;
                  }
                });
                //if garment size wasn´t created adds it
                if (!containsSize) {
                  garmentCount.sizes.push({
                    size: garment.size,
                    quantity: garment.quantity,
                  });
                }
                containsGarment = true;
              }
            });
            //if garment wasn´t created adds it
            if (!containsGarment) {
              garmentsCounts.push({
                name: garment.prenda.name,
                sizes: [
                  {
                    size: garment.size,
                    quantity: garment.quantity,
                  },
                ],
              });
            }
          }
        });
        workplace.garments = garmentsCounts;
        delete workplace.allGarments;
      });

      //Add totals
      // eslint-disable-next-line
      allWorkplaces.map((workplace) => {
        // eslint-disable-next-line
        workplace.garments.map((garmentsCount, index) => {
          let total = 0;
          // eslint-disable-next-line
          garmentsCount.sizes.map((size) => {
            total = total + size.quantity;
          });
          workplace.garments[index].total = total;
        });
      });

      //Paginate results each workplace new page, each 3 garments new page
      // eslint-disable-next-line
      allWorkplaces.map((workplace) => {
        workplace.garments = chunkArray(workplace.garments, 3);
        // eslint-disable-next-line
        workplace.garments.map((garmentPage) => {
          newPagedResults.push(
            { workplace: workplace.workplace,
              garments: garmentPage
            });
        });
      });

      setPaginatedResults(newPagedResults);
      setShowDocuments(true);
    } else {
      setShowDocuments(false);
    }
    // eslint-disable-next-line
  }, [results]);

  return (
    <div id="count-to-print-workplaces">
      {showDocuments && (
        <>
          {
            // eslint-disable-next-line
            paginatedResults.map((result, index) => (
              <div key={"count-garments-result-" + index}>
                <div>
                  <div
                    id={"count-garments"}
                    style={{
                      boxSizing: "border-box",
                      border: "1px solid black",
                      height: `${
                        index !== paginatedResults.length - 1 ? "100%" : "99%"
                      }`,
                      minHeight: "100%",
                      width: "100%",
                      margin: "auto",
                      marginTop: "0px !important",
                      fontFamily: "SF Pro Display Regular",
                      marginBottom: `${
                        index !== paginatedResults.length - 1 ? "3%" : "0"
                      }`,
                      position: "relative",
                    }}
                    key={Math.floor(Math.random() * 100001)}
                  >
                    <CountByWorkplaceTemplate
                      clientName={pedido.client.name}
                      result={result}
                      workplace={result.workplace}
                    />
                  </div>
                </div>
              </div>
            ))
          }
          <CountWorkplacesReady setFrameTarjet={setFrameTarjet} />
        </>
      )}
    </div>
  );
};

export default CountByWorkplacesTemplate;
