import React from "react";
//Icons svg
import { ReactComponent as LogoOroel } from "../../../../assets/images/logo-oroel.svg";

const DeliveryNoteTemplate = ({ pedido, result }) => {
  //Date strings
  const getDateString = () => {
    var meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
    var diasSemana = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    var f = new Date();
    return (
      diasSemana[f.getDay()] +
      ", " +
      f.getDate() +
      " de " +
      meses[f.getMonth()] +
      " de " +
      f.getFullYear()
    );
  };

  const getDataFromWorkplace = () => {
    let address = "";
    let postalCode = "";
    let location = "";
    let province = "";
    let country = "";

    // eslint-disable-next-line
    pedido.client.workplaces.map((workplace) => {
      if (workplace.name.toLowerCase() === result.workplace.toLowerCase()) {
        address = workplace.address;
        postalCode = workplace.postal_code;
        location = workplace.location.name;
        province = workplace.province.name;
        country = workplace.country.name;
      }
    });

    return `${address}, ${postalCode} ${location} - ${province} (${country})`;
  };

  const dataFromWorkplace = getDataFromWorkplace();

  return (
    <>
      {/* Delivery note heading */}
      <div
        id="delivery-note-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          padding: "2.5% 5%",
          borderBottom: "1px solid black",
        }}
      >
        <div
          id="delivery-note__heading-column"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div>
            <LogoOroel />
          </div>
          <div
            style={{
              fontFamily: "SF Pro Display",
              fontSize: "12px",
              fontWeight: 700,
              margin: "12px 0",
            }}
          >
            Confecciones Oroel S.A.
          </div>
          <div style={{ fontSize: "12px" }}>
            Calle Los Ángeles, 10 - Po. Centrovía 50.198
            <br />
            La Muela (Zaragoza)
            <br />
            Tel. 976-14-92-60 - Fax. 976-14-92-52
            <br />
            E-Mail: comercial@oroel.com
            <br />
            Web: www.grupooroel.com
          </div>
        </div>
        <div
          id="delivery-note__heading-column"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div>
            <h1
              style={{
                marginBottom: 0,
                fontFamily: "SF Pro Display",
                fontSize: "40px",
              }}
            >
              ALBARÁN
            </h1>
          </div>
          <div
            style={{
              fontFamily: "SF Pro Display",
              fontWeight: 600,
              margin: "12px 0",
              fontSize: "12px",
            }}
          >
            {getDateString()}
          </div>
        </div>
      </div>
      {/* Worker data */}
      <div
        id="worker-data-container"
        style={{ padding: "2.5% 5%", fontSize: "12px" }}
      >
        <div
          style={{
            fontFamily: "SF Pro Display",
            fontWeight: 600,
            marginBottom: "2.5%",
          }}
        >
          Datos del trabajador
        </div>
        <div style={{ display: "flex", gap: "5%" }}>
          <div style={{ width: "25%", display: "flex", gap: "5%" }}>
            <div style={{ width: "70%" }}>Código de empleado:</div>
            <div style={{ width: "25%", fontWeight: 600 }}>
              {result.worker_code}
            </div>
          </div>
          <div style={{ width: "30%", display: "flex", gap: "5%" }}>
            <div style={{ width: "35%" }}>Nombre:</div>
            <div style={{ width: "60%", fontWeight: 600 }}>
              {result.worker_name}
            </div>
          </div>
          <div style={{ width: "30%", display: "flex", gap: "5%" }}>
            <div style={{ width: "55%" }}>DNI:</div>
            <div style={{ width: "40%", fontWeight: 600 }}>{result.dni}</div>
          </div>
        </div>

        <div style={{ display: "flex", gap: "5%" }}>
          <div style={{ width: "40%", display: "flex", gap: "5%" }}>
            <div style={{ width: "70%" }}>Puesto:</div>
            <div style={{ width: "25%", fontWeight: 600 }}>{result.job}</div>
          </div>
          <div style={{ width: "15%", display: "flex", gap: "5%" }}>
            <div style={{ width: "35%" }}>Sexo:</div>
            <div style={{ width: "60%", fontWeight: 600 }}>{result.sex}</div>
          </div>
          <div style={{ width: "30%", display: "flex", gap: "5%" }}>
            <div style={{ width: "55%" }}>Centro de trabajo:</div>
            <div style={{ width: "40%", fontWeight: 600 }}>
              {result.workplace}
            </div>
          </div>
        </div>

        <div style={{ display: "flex", gap: "5%", marginTop: "2.5%" }}>
          <div style={{ width: "13.5%" }}>Entregar en:</div>
          <div style={{ width: "60%", fontWeight: 600 }}>
            {dataFromWorkplace}
          </div>
        </div>
      </div>
      {/* Rows Headers */}
      <div
        className="delivery-note__rows-heading-container"
        style={{
          display: "flex",
          flexWrap: "wrap",
          padding: "2.5%",
          borderBottom: "1px solid black",
          borderTop: "1px solid black",
          margin: "2.5% 5% 5% 5%",
        }}
      >
        <div
          style={{
            width: "75%",
            fontFamily: "SF Pro Display",
            fontWeight: 700,
          }}
        >
          Prenda
        </div>
        <div
          style={{
            width: "20%",
            fontFamily: "SF Pro Display",
            fontWeight: 700,
          }}
        >
          Talla
        </div>
        <div
          style={{
            width: "5%",
            fontFamily: "SF Pro Display",
            fontWeight: 700,
          }}
        >
          Cant.
        </div>
      </div>
      {/* Rows Garments */}
      {result.garments.map((garment, index) => (
        <div
          className="label__rows-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            padding: "0% 2.5%",
            margin: "2% 5%",
          }}
          key={"prenda" + index}
        >
          <div
            style={{
              width: "75%",
            }}
          >
            {garment.prenda.name}
          </div>
          <div
            style={{
              width: "20%",
            }}
          >
            {garment.size}
          </div>
          <div
            style={{
              width: "5%",
            }}
          >
            {garment.quantity}
          </div>
        </div>
      ))}
      {/* Delivery note footer */}
      <div
        id="delivery-note-container"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          padding: "2.5% 5%",
          border: "1px solid black",
          position: "absolute",
          bottom: 0,
          left: 0,
        }}
      >
        <div
          id="delivery-note__heading-column"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            fontFamily: "SF Pro Display",
            width: "40%",
          }}
        >
          <div>
            <p style={{fontWeight: 600, fontSize: "22px", lineHeight: "22px"}}>Firma de conformidad</p>
          </div>
          <div
            style={{
              fontSize: "12px",
              margin: "0",
            }}
          >
            {`El trabajador ${result.worker_name} mediante su firma confirma la correcta recepción de los artículos que se detallan en este albarán.`}
          </div>
        </div>
        <div
          id="delivery-note__heading-column"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            border: "1px solid black",
            position: "relative",
            padding: "1.5%",
            width: "40%",
            height: "100px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: "5%",
              fontFamily: "SF Pro Display",
              fontWeight: 600,
              fontSize: "12px",
            }}
          >
            <p>Conforme.</p>
          </div>
          <div style={{ position: "absolute", bottom: 0, left: "5%" }}>
            <p style={{ fontSize: "12px" }}>
              <span style={{ fontWeight: 600 }}>Firmado: </span>
              {result.worker_name}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeliveryNoteTemplate;
