import React, { useState, useEffect } from "react";
//Icons svg
import { ReactComponent as Labels11 } from "../../../../assets/images/labels1-1.svg";
import { ReactComponent as Labels18 } from "../../../../assets/images/labels1-8.svg";
import { ReactComponent as Labels116 } from "../../../../assets/images/labels1-16.svg";
//Templates
import Labels11Template from "../label/Labels11Template";
import Labels18Template from "../label/Labels18Template";
import Labels116Template from "../label/Labels116Template";

const PrintOrderLabelsButton = () => {
  //States
  const [frameTarget, setFrameTarjet] = useState("");
  const [is11Ready, setIs11Ready] = useState(false);
  const [is18Ready, setIs18Ready] = useState(false);
  const [is116Ready, setIs116Ready] = useState(false);

  //Functions
  const retrieveAction = (e) => {
    switch (e.target.dataset.type) {
      case "1-1":
        setIs11Ready(true);
        break;
      case "1-8":
        setIs18Ready(true);
        break;
      case "1-16":
        setIs116Ready(true);
        break;
      default:
        break;
    }
  };

  const sendToPrint = (frameTarget) => {
    var content = document.getElementById(frameTarget);

    var pri = document.getElementById("documents-to-print").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
    setFrameTarjet("");
    setIs11Ready(false);
    setIs18Ready(false);
    setIs116Ready(false);
  };

  //useEffect
  useEffect(() => {
    if (frameTarget !== "") {
      sendToPrint(frameTarget);
    }
  }, [frameTarget]);

  return (
    <div className="print-document__container labels">
      <div className="print-document__texts-container">
        <h2 className="print-document__texts-title">Etiquetas</h2>
      </div>
      <div className="print-document__buttons-container">
        <div className="print-document__button-container">
          <div
            className="hitPoint"
            data-type="1-1"
            onClick={retrieveAction}
          ></div>
          <div className="print-document__buttons-icon">
            <Labels11 />
          </div>
          <div className="print-document__buttons-texts">
            <h2 className="print-document__texts-title">1/1</h2>
            <p className="print-document__texts-text">
              Cabe una etiqueta por cada folio.
            </p>
          </div>
        </div>
        <div className="print-document__button-container">
          <div
            className="hitPoint"
            data-type="1-8"
            onClick={retrieveAction}
          ></div>
          <div className="print-document__buttons-icon">
            <Labels18 />
          </div>
          <div className="print-document__buttons-texts">
            <h2 className="print-document__texts-title">1/8</h2>
            <p className="print-document__texts-text">
              Caben 8 etiquetas por cada folio.
            </p>
          </div>
        </div>
        <div className="print-document__button-container">
          <div
            className="hitPoint"
            data-type="1-16"
            onClick={retrieveAction}
          ></div>
          <div className="print-document__buttons-icon">
            <Labels116 />
          </div>
          <div className="print-document__buttons-texts">
            <h2 className="print-document__texts-title">1/16</h2>
            <p className="print-document__texts-text">
              Caben 16 etiquetas por cada folio.
            </p>
          </div>
        </div>
      </div>
      {is11Ready && <Labels11Template setFrameTarjet={setFrameTarjet} />}
      {is18Ready && <Labels18Template setFrameTarjet={setFrameTarjet} />}
      {is116Ready && <Labels116Template setFrameTarjet={setFrameTarjet} />}
    </div>
  );
};

export default PrintOrderLabelsButton;
