export function prendaVerModal(item,entity){
    return {
        id: item.id,
        infoTitle: item.name,
        image: item.image,
        texts: [
          {
            text: "Referencia",
            value: item.reference,
          },
          {
            text: "Tipo de prenda",
            value: item.type,
          },
          {
            text: "Descripción",
            value: item.description,
          },
        ],
        buttons: [
          {
            text: "Editar",
            action: "link",
            link: "/editar-prenda",
            classes: "neutral",
            entity: entity,
          },
          {
            text: "Cerrar",
            action: "closeModal",
            classes: "safe",
            entity: entity,
          },
        ],
      }
}