import React, { useState, useEffect } from "react";
//Icons svg
import { ReactComponent as MagnifyingGlass } from "../../../../assets/images/magnifyingGlass.svg";
import { ReactComponent as Chevron } from "../../../../assets/images/chevron.svg";
//GraphQL
import { useLazyQuery, gql } from "@apollo/client";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { loadFormValue } from "../../../../actions/formDataActions";

const PrendasCreateType = ({ defaultValue }) => {
  //Store data
  const { formValues } = useSelector((state) => state.dataForm);

  //States
  const [valuePlaceholder, setValuePlaceholder] = useState(
    "Seleccione un tipo de prenda"
  );
  const [displayError, setDisplayError] = useState(false);
  const [selectOptions, setSelectOptions] = useState({
    originalOptions: false,
    workOptions: false,
  });
  const [activeValue, setActiveValue] = useState("");

  //API
  const GET_PRENDA_TYPES = gql`
    query getPrendaTypes {
      getPrendaTypes {
        id
        name
      }
    }
  `;

  const [getPrendaTypes, { error, data }] = useLazyQuery(GET_PRENDA_TYPES);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));

  //Functions
  const loadInputValue = (e) => {
    //Loads input value in store
    storeLoadFormValue([
      e.target.dataset.name,
      parseInt(e.target.dataset.optionvalue),
    ]);
    setValuePlaceholder(e.target.dataset.optionname);

    setActiveValue(e.target.dataset.optionname);
  };

  const filterSelectOptions = (e) => {
    //Loads new select options according to search field value
    const searchTerm = e.target.value;
    const newOptions = [];
    if (selectOptions) {
      // eslint-disable-next-line
      selectOptions.originalOptions.map((originalOption) => {
        if (
          originalOption.name.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          newOptions.push(originalOption);
        }
      });
    }
    setSelectOptions({ ...selectOptions, workOptions: newOptions });
  };

  const isCurrent = (option) => {
    //Load style if item is selected
    if (option === formValues["tipo_prenda_id"]) {
      return " current";
    } else {
      return " ";
    }
  };

  //useEffect
  useEffect(() => {
    if (formValues.tipo_prenda_id) {
      setDisplayError(false);
    } else {
      setDisplayError(true);
    }
    // eslint-disable-next-line
  }, [formValues.tipo_prenda_id]);

  useEffect(() => {
    //get all countries
    getPrendaTypes();
    //In case of update, load current value into form values
    if (defaultValue) {
      storeLoadFormValue(["tipo_prenda_id", defaultValue]);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && !error) {
      setSelectOptions({
        originalOptions: data.getPrendaTypes,
        workOptions: data.getPrendaTypes,
      });
      //Load name in input
      if (!formValues.tipo_prenda_id) {
        setActiveValue("");
      } else {
        // eslint-disable-next-line
        data.getPrendaTypes.map((prendaType) => {
          if (prendaType.id === formValues.tipo_prenda_id) {
            setActiveValue(prendaType.name);
          }
        });
      }
      //In case of update, load current value into placeholder
      if (defaultValue) {
        // eslint-disable-next-line
        data.getPrendaTypes.map((prendaType) => {
          if (prendaType.id === defaultValue) {
            setActiveValue(prendaType.name);
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [data, error]);

  return (
    <>
      {data &&
        data.getPrendaTypes &&
        selectOptions &&
        selectOptions.workOptions && (
          <div className="input-container select">
            <div className="input-label">Tipo de prenda</div>
            <>
              {/* Display collapsed */}
              <div className="input-select-collapsed">
                <input
                  type="text"
                  disabled
                  placeholder={valuePlaceholder}
                  className={displayError ? "errorInput" : ""}
                  value={activeValue}
                />
                <Chevron />
              </div>
              {/* Display expanded */}
              <div className="input-select-expanded">
                <div className="input-select-expanded__container">
                  {/* fixed search */}
                  <div className="input-select-expanded__search">
                    <input type="text" onChange={filterSelectOptions} />
                    <MagnifyingGlass />
                  </div>
                  <div className="input-select-expanded__option-container">
                    {/* Filter - Select - Options - API */}
                    {selectOptions &&
                      selectOptions.workOptions.map((item, index) => (
                        <div
                          className={
                            "input-select-expanded__option " +
                            isCurrent(item.id)
                          }
                          key={index}
                          data-name="tipo_prenda_id"
                          data-optionvalue={item.id}
                          data-optionname={item.name}
                          onClick={loadInputValue}
                        >
                          {item.name}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </>
          </div>
        )}
    </>
  );
};

export default PrendasCreateType;
