import React, { useState, useEffect } from "react";
import { getNoImageImage } from "../../../../utilities/form/getNoImageImage";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { loadFormValue } from "../../../../actions/formDataActions";

const PrendasCreateImage = ({ defaultValue }) => {
  //States
  const [displayError, setDisplayError] = useState(false);

  //Store data
  const { formValues, settings } = useSelector((state) => state.dataForm);

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));

  //Functions
  const loadInputValue = (e) => {
    const file = e.target.files[0];
    if (file) {
      getBase64(file, storeLoadFormValue);
    }
  };

  //useEffect
  useEffect(() => {
    let imageFrame = document.getElementById("preview");
    if (formValues && formValues.image && formValues.image !== "") {
      //Load on frame
      imageFrame.src = formValues.image;
      setDisplayError(false);
    } else {
      const noImageImage = defaultValue
        ? defaultValue
        : getNoImageImage(settings.entity, settings.queryName);
      imageFrame.src = noImageImage;
    }

    // eslint-disable-next-line
  }, [formValues.image]);

  useEffect(() => {
    //Check field validation
    if (formValues && formValues.image && formValues.image !== "") {
      setDisplayError(false);
    } else {
      setDisplayError(true);
    }
    // eslint-disable-next-line
  }, [formValues.image]);

  useEffect(() => {
    //In case of update, load current value into form values
    if (defaultValue) {
      storeLoadFormValue(["image", defaultValue]);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="input-container fileUpload col-75">
      <div className="input-container-left ">
        <div className="input-label">Imagen</div>
        <div className="input-text">
          Se recomienda que las imágenes tengan un fondo blanco y sean
          cuadradas.{" "}
        </div>
        <div className="input-text">
          Formatos de imagen admitidos: JPG, GIF. Tamaño máximo de imagen
          permitido: 300kb.
        </div>
        <input
          type="file"
          name={"image"}
          placeholder={"Seleccione una imagen"}
          onChange={loadInputValue}
          className={displayError ? "errorInput" : ""}
        />
      </div>
      <div
        className={
          "input-container-right" + (displayError ? " errorInputImage" : "")
        }
      >
        <img id="preview" src={"#"} alt="Imagen de la prenda" />
      </div>
    </div>
  );
};

export default PrendasCreateImage;

function getBase64(file, storeLoadFormValue) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    storeLoadFormValue(["image", reader.result]);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
}
