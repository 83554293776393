import React, { useState, useEffect } from "react";
//Icons svg
import { ReactComponent as MagnifyingGlass } from "../../../../../assets/images/magnifyingGlass.svg";
import { ReactComponent as Chevron } from "../../../../../assets/images/chevron.svg";
//Redux
import { useSelector, useDispatch } from "react-redux";
import { loadFormValue } from "../../../../../actions/formDataActions";

const FieldSelectAssignment = ({ name, options }) => {
  //Store data
  const { formValues } = useSelector((state) => state.dataForm);
  //States
  const [valuePlaceholder, setValuePlaceholder] = useState(
    "Seleccione un valor"
  );
  const [displayError, setDisplayError] = useState(false);
  const [selectOptions, setSelectOptions] = useState({
    originalOptions: false,
    workOptions: false,
  });
  const [activeValue, setActiveValue] = useState("");

  //Redux actions
  const dispatch = useDispatch();
  const storeLoadFormValue = (valuePair) => dispatch(loadFormValue(valuePair));

  //Functions
  const loadInputValue = (e) => {
    //Loads input value in store
    storeLoadFormValue([e.target.dataset.name, e.target.dataset.optionvalue]);
    setValuePlaceholder(e.target.dataset.optionvalue);

    setActiveValue(e.target.dataset.optionvalue);
  };

  const filterSelectOptions = (e) => {
    //Loads new select options according to search field value
    const searchTerm = e.target.value;
    const newOptions = [];
    if (selectOptions) {
      // eslint-disable-next-line
      selectOptions.originalOptions.map((originalOption) => {
        if (
          originalOption.name.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          newOptions.push(originalOption);
        }
      });
    }
    setSelectOptions({ ...selectOptions, workOptions: newOptions });
  };

  const isCurrent = (option) => {
    //Load style if item is selected
    if (option === formValues["template_id"]) {
      return " current";
    } else {
      return " ";
    }
  };

  //useEffect
  useEffect(() => {
    setSelectOptions({
      originalOptions: options,
      workOptions: options,
    });
    //Load name in input
    if (!formValues[name.name]) {
      setActiveValue("");
    } else {
      // eslint-disable-next-line
      options.map((option) => {
        if (option === formValues[option.name]) {
          setActiveValue(option.name);
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //Verify mandatory fields
    if (formValues) {
      if (
        (name.name === "name" &&
          (!formValues.name || !formValues.name === "")) ||
        (name.name === "workplace" &&
          (!formValues.workplace || !formValues.workplace === "")) ||
        (name.name === "job" && (!formValues.job || !formValues.job === ""))
      ) {
        setDisplayError(true);
      } else {
        setDisplayError(false);
      }
    } else {
      setDisplayError(true);
    }
    // eslint-disable-next-line
  }, [formValues.name, formValues.workplace, formValues.job]);

  return (
    <>
      {options && name && (
        <div className="input-container select col-4">
          <div className="input-label">{name.value}</div>
          <>
            {/* Display collapsed */}
            <div className="input-select-collapsed">
              <input
                type="text"
                disabled
                placeholder={valuePlaceholder}
                className={displayError ? "errorInput" : ""}
                value={activeValue}
              />
              <Chevron />
            </div>
            {/* Display expanded */}
            <div className="input-select-expanded">
              <div className="input-select-expanded__container">
                {/* fixed search */}
                <div className="input-select-expanded__search">
                  <input type="text" onChange={filterSelectOptions} />
                  <MagnifyingGlass />
                </div>
                <div className="input-select-expanded__option-container">
                  {/* Filter - Select - Options - API */}
                  {selectOptions &&
                    selectOptions.workOptions &&
                    selectOptions.workOptions.map((item, index) => (
                      <div
                        className={
                          "input-select-expanded__option " +
                          isCurrent(item.value)
                        }
                        key={index}
                        data-name={name.name}
                        data-optionvalue={item.value}
                        data-optionname={item.name}
                        onClick={loadInputValue}
                      >
                        {item.value}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default FieldSelectAssignment;
