import { prendaVerModal } from "./prendaVerModal";
import { prendaEliminarModal } from "./prendaEliminarModal";
import { prendaTypeVerModal } from "./prendaTypeVerModal";
import { prendaTypeEliminarModal } from "./prendaTypeEliminarModal";
import { clienteEliminarModal } from "./clienteEliminarModal";
import { clienteCancelarModal } from "./clienteCancelarModal";
import { pedidoEliminarModal } from "./pedidoEliminarModal";
import { lineaPedidoEliminarModal } from "./lineaPedidoEliminarModal";
import { lineaPedidoCancelarModal } from "./lineaPedidoCancelarModal";
import { lineaPedidoDescartarModal } from "./lineaPedidoDescartarModal";
import { countryEliminarModal } from "./countryEliminarModal";
import { countryVerModal } from "./countryVerModal";
import { provinceEliminarModal } from "./provinceEliminarModal";
import { provinceVerModal } from "./provinceVerModal";
import { locationEliminarModal } from "./locationEliminarModal";
import { locationVerModal } from "./locationVerModal";
import { workplaceEliminarModal } from "./workplaceEliminarModal";
import { workplaceVerModal } from "./workplaceVerModal";
import { businessGroupEliminarModal } from "./businessGroupEliminarModal";
import { businessGroupVerModal } from "./businessGroupVerModal";

export function dispatchModalContent(
  entity,
  item,
  textAction,
  info,
  formValues
) {
  let res;
  switch (entity) {
    case "prenda":
      if (textAction === "Ver") {
        res = prendaVerModal(item, entity);
      } else if (textAction === "Eliminar") {
        res = prendaEliminarModal(item, entity);
      }
      break;
    case "prendaType":
      if (textAction === "Ver") {
        res = prendaTypeVerModal(item, entity);
      } else if (textAction === "Eliminar") {
        res = prendaTypeEliminarModal(item, entity);
      }
      break;
    case "cliente":
      if (textAction === "Eliminar") {
        res = clienteEliminarModal(item, entity);
      } else if (textAction === "Cancelar") {
        res = clienteCancelarModal(item, entity);
      }
      break;
    case "pedido":
      if (textAction === "Eliminar") {
        res = pedidoEliminarModal(item, entity, info);
      }
      break;
    case "pedidoRow":
      switch (textAction) {
        case "Eliminar":
          res = lineaPedidoEliminarModal(item, entity);
          break;
        case "Cancelar":
          res = lineaPedidoCancelarModal(entity, formValues);
          break;
        case "Descartar cambios":
          res = lineaPedidoDescartarModal(entity, formValues);
          break;
        default:
          break;
      }
      break;
    case "country":
      if (textAction === "Eliminar") {
        res = countryEliminarModal(item, entity);
      } else if (textAction === "Ver") {
        res = countryVerModal(item, entity);
      }
      break;
    case "province":
      if (textAction === "Eliminar") {
        res = provinceEliminarModal(item, entity);
      } else if (textAction === "Ver") {
        res = provinceVerModal(item, entity);
      }
      break;
    case "location":
      if (textAction === "Eliminar") {
        res = locationEliminarModal(item, entity);
      } else if (textAction === "Ver") {
        res = locationVerModal(item, entity);
      }
      break;
    case "workplace":
      if (textAction === "Eliminar") {
        res = workplaceEliminarModal(item, entity);
      } else if (textAction === "Ver") {
        res = workplaceVerModal(item, entity);
      }
      break;
    case "businessGroup":
      if (textAction === "Eliminar") {
        res = businessGroupEliminarModal(item, entity);
      } else if (textAction === "Ver") {
        res = businessGroupVerModal(item, entity);
      }
      break;
    default:
      break;
  }
  return res;
}
