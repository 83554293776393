export function getNewResults(originalData, search, filters, orderBy) {
  //Gets new results to show using search if any and filter if any
  let res = {
    getPedidos: [],
  };

  // eslint-disable-next-line
  originalData.getPedidos.map((result) => {
    const searchOK = validateResultWithSearch(result, search);
    const filtersOK = validateResultWithFilters(result, filters);

    if (searchOK && filtersOK) {
      res.getPedidos.push(result);
    }
  });

  //Use OrderBy items
  const orderedRes = getOrderedRes(res.getPedidos, orderBy);
  res.getPedidos = orderedRes;
  return res;
}

function removeAccents(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

function validateResultWithSearch(result, search) {
  //Filter results with search
  let res = false;
  //Filters by search if any
  if (search !== "") {
    if (
      result &&
      removeAccents(result.order_number.toLowerCase()).includes(
        removeAccents(search.toLowerCase())
      )
    ) {
      res = true;
    }
  } else {
    res = true;
  }
  return res;
}

function validateResultWithFilters(result, filters) {
  //Filter results with filters
  let res = true;
  let filterValidation = [];

  //Check if is date filter
  if (filters[0].name === "dateFrom") {
    res = validateResultWithDateFilters(result, filters);
  } else {
    for (let i = 0; i < filters.length; i++) {
      // If filter matches or is set to none
      if (filters[i].value === result.type || filters[i].value === "") {
        filterValidation[i] = true;
      } else {
        filterValidation[i] = false;
      }
    }

    // eslint-disable-next-line
    filterValidation.map((validation) => {
      // If any filter isn´t disabled or doesn´t match
      if (!validation) {
        res = false;
      }
    });
  }

  return res;
}

function validateResultWithDateFilters(result, filters) {
  let res = false;
  let dateFrom = new Date(1800, 1, 1);
  let dateTo = new Date();
  const itemDate = new Date(result.order_date);
  // If there´s date from convert to date object
  if (filters[0].value && filters[0].value !== "") {
    dateFrom = new Date(filters[0].value);
  }

  // If there´s date to convert to date object
  if (filters[1].value && filters[1].value !== "") {
    dateTo = new Date(filters[1].value);
  }

  if (itemDate >= dateFrom && itemDate <= dateTo) {
    res = true;
  }

  return res;
}

function getOrderedRes(results, orderBy) {
  let res = [];
  //If exists orderBy config
  if (orderBy && orderBy.length > 0) {
    //If there are two orders
    if (orderBy.length === 2) {
      const firstFieldToShort = orderBy[0].field;
      const secondFieldToShort = orderBy[1].field;
      res = results.sort(function (a, b) {
        var aSize = a[firstFieldToShort];
        var bSize = b[firstFieldToShort];
        var aSizeLow = removeAccents(aSize.toLowerCase());
        var bSizeLow = removeAccents(bSize.toLowerCase());
        var aLow = a[secondFieldToShort];
        var bLow = b[secondFieldToShort];
        var aLowLow = removeAccents(aLow.toLowerCase());
        var bLowLow = removeAccents(bLow.toLowerCase());

        if (aSizeLow === bSizeLow) {
          return aLowLow < bLowLow ? -1 : aLowLow > bLowLow ? 1 : 0;
        } else {
          return aSizeLow < bSizeLow ? -1 : 1;
        }
      });
    } else {
      const fieldToShort = orderBy[0].field;
      // Sort depending of criteria
      if (orderBy[0].criteria === "asc") {
        res = results.sort((a, b) => {
          const aValue = a[fieldToShort];
          const bValue = b[fieldToShort];
          const aValueLower = removeAccents(aValue.toLowerCase());
          const bValueLower = removeAccents(bValue.toLowerCase());

          if (aValueLower > bValueLower) {
            return -1;
          }
          return aValueLower < bValueLower ? 1 : 0;
        });
      } else {
        res = results.sort((a, b) => {
          const aValue = a[fieldToShort];
          const bValue = b[fieldToShort];
          const aValueLower = removeAccents(aValue.toLowerCase());
          const bValueLower = removeAccents(bValue.toLowerCase());
          if (aValueLower < bValueLower) return -1;
          return aValueLower > bValueLower ? 1 : 0;
        });
      }
    }
  } else {
    return results;
  }
  return res;
}
